import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ps-micro-surveys',
  templateUrl: './micro-surveys.component.html',
  styleUrls: ['../base-form.css'],
})
export class MicroSurveysComponent {
  @Input()
  form!: FormGroup;

  constructor() {}
}
