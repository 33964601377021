<div class="container">
  <div class="row">
    <div class="col-sm-4">
      <div class="col-sm-12 col-md-12 form-group mb-35">
        <ps-ui-select-dropdown
          [dropdownItems]="countryList"
          [field]="'name'"
          [enableSearch]="true"
          labelNone="Country"
          [label]="selectedCountry?.name"
          (selectedItem)="selectCountry($event)"
        >
        </ps-ui-select-dropdown>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="col-sm-12 col-md-12 form-group mb-35">
        <ps-ui-select-dropdown
          [dropdownItems]="listOfLanguages"
          [field]="'name'"
          [enableSearch]="true"
          labelNone="Language"
          [label]="selectedLanguage?.name"
          [loading]="false"
          [disabled]="!selectedCountry"
          (selectedItem)="selectLanguage($event)"
        >
        </ps-ui-select-dropdown>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="col-sm-12 col-md-12 form-group mb-35">
        <ps-ui-select-dropdown
          [dropdownItems]="listOfQualifications"
          [field]="'desc'"
          [spanField]="'qualification_code'"
          [enableSearch]="true"
          labelNone="Select Qualification"
          [label]="showTextQualification"
          [loading]="false"
          [disabled]="!selectedLanguage"
          (selectedItem)="selectQualification($event)"
        >
        </ps-ui-select-dropdown>
      </div>
    </div>
  </div>
  <div class="row my-4" *ngIf="adminAccess && !selectedQualification">
    <hr />
    <div class="d-flex flex-column align-items-center">
      <h3 class="my-2">Global Upload</h3>
      <div class="d-flex flex-row align-items-center mt-3">
        <input
          #globalFileGenpopUpload
          class="form-control"
          type="file"
          [hidden]="true"
          (change)="selectFile($event)"
        />
        <input
          class="form-control"
          type="text"
          placeholder=".csv"
          name="fileName"
          (click)="globalFileGenpopUpload.click()"
          [(ngModel)]="fileName"
          [readonly]="true"
        />
        <button
          psUiButton
          color="primary"
          size="default"
          [disabled]="globalFileGenpopUpload.files?.length === 0"
          (click)="uploadGenpopFile(globalFileGenpopUpload, true)"
          class="ms-3"
        >
          Upload
        </button>
        <div class="setting-info">
          <i class="fa fa-info-circle" aria-hidden="true"></i>
          <p style="white-space: normal">
            Uploading a Global new file will remove the previous Income values,
            Percentages, and Price Multipliers, for ALL Countries, languages and
            qualifications specified in the .csv File. Be sure to include a
            complete table to replace these values.
          </p>
        </div>
      </div>
      <div class="mt-2">
        <a
          href="assets/templates/genpop-feasibility-bulk-upload-template.csv"
          target="_blank"
        >
          Download Template
        </a>
      </div>
    </div>
  </div>
  <div
    class="row justify-content-center padding-top-div"
    [hidden]="!selectedQualification"
  >
    <div class="col-sm-4">
      <input
        #fileGenpopUpload
        class="form-control"
        type="file"
        [hidden]="true"
        (change)="selectFile($event)"
      />
      <input
        class="form-control"
        id="formFile"
        type="text"
        placeholder=".csv"
        name="fileName"
        [(ngModel)]="fileName"
        (click)="fileGenpopUpload.click()"
        [readonly]="true"
      />
    </div>
    <div class="col-sm-2">
      <button
        psUiButton
        color="primary"
        size="default"
        [disabled]="fileGenpopUpload.files?.length === 0"
        (click)="uploadGenpopFile(fileGenpopUpload)"
        class="pull-left"
      >
        Upload
      </button>
      <div class="tooltip-visible">
        <div class="fa fa-info-circle tootip-setting-info align-middle">
          <p>
            Uploading a new file will remove the previous Income values,
            Percentages, and Price Multipliers. Be sure to include a complete
            table to replace these values.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="row" [hidden]="!selectedQualification">
    <div class="col-sm-12" style="padding-top: 5%">
      <div class="form-group row base-multiplier">
        <!--   Pagination Starts  -->
        <nav *ngIf="pageCounter.length > 1" class="col-sm-7">
          <ul class="pagination">
            <li class="page-item">
              <button
                class="page-link"
                [disabled]="!pageConfig.pagination_ready"
                (click)="setPageByAction('back')"
              >
                previous
              </button>
            </li>
            <li *ngFor="let page of pageCounter" class="page-item">
              <button
                [ngClass]="
                  page === pageConfig.current_page &&
                  pageConfig.pagination_ready
                    ? 'page-link selected-item'
                    : 'page-link element-disabled'
                "
                [disabled]="!pageConfig.pagination_ready"
                (click)="setCurrentPage(page)"
              >
                {{ page }}
              </button>
            </li>
            <li
              *ngIf="pageConfig.last_page > pageConfig.page_limit"
              class="page-item"
            >
              <button
                [ngClass]="
                  pageConfig.current_page > pageConfig.page_limit &&
                  pageConfig.current_page < pageConfig.last_page
                    ? 'page-link selected-item'
                    : 'page-link'
                "
                [disabled]="!pageConfig.pagination_ready"
                (click)="setPageInput()"
              >
                ...
              </button>
            </li>
            <div *ngIf="pageConfig.page_input" class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Page number"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                [(ngModel)]="pageConfig.current_page"
                (change)="setCurrentPage(pageConfig.current_page)"
              />
              <span class="input-group-text" id="basic-addon2">
                Choose a page number. Eg: 11
              </span>
            </div>
            <li
              *ngIf="pageConfig.last_page > pageConfig.page_limit"
              class="page-item"
            >
              <button
                [ngClass]="
                  pageConfig.current_page === pageConfig.last_page
                    ? 'page-link selected-item'
                    : 'page-link'
                "
                [disabled]="!pageConfig.pagination_ready"
                (click)="setCurrentPage(pageConfig.last_page)"
              >
                {{ pageConfig.last_page }}
              </button>
            </li>
            <li class="page-item">
              <button
                class="page-link"
                [disabled]="!pageConfig.pagination_ready"
                (click)="setPageByAction('next')"
              >
                next
              </button>
            </li>
          </ul>
        </nav>
        <!--   Pagination Ends  -->
        <label class="col-sm-2 text-right">Base Multiplier</label>
        <div class="col-sm-2" *ngIf="!editBaseMultiplier">
          <div class="edit-text-icon">
            <label class="edit-text">{{ baseMultiplier }}</label>
            <i
              class="fa fa-pencil-square-o editBtn"
              aria-hidden="true"
              (click)="editBaseMultiplier = true"
            ></i>
          </div>
        </div>
        <div class="col-sm-3" *ngIf="editBaseMultiplier">
          <div class="row align-center justify-content-end">
            <div class="col-sm-7">
              <input
                type="number"
                class="form-control"
                [(ngModel)]="baseMultiplier"
              />
            </div>
            <div class="col-sm-3 text-right">
              <i
                class="fa fa-check-circle-o"
                aria-hidden="true"
                (click)="updateBaseMultiplier()"
              ></i>
              <i
                class="fa fa-times-circle-o"
                aria-hidden="true"
                (click)="resetBaseMultiplier()"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <input
        class="col-sm-12"
        [(ngModel)]="searchText"
        type="text"
        placeholder="Search For Conditions ..."
        (input)="searchGenpopData(searchText)"
      />
      <table class="table table-striped border mt-1">
        <thead>
          <th>Condition Code</th>
          <th>Condition Name</th>
          <th>Percentage</th>
          <th>Price Multiplier</th>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let genpopData of selectedQualification?.qualification_code ===
              229
                ? genpopList
                : (genpopList
                  | filterTheGenpopTable : searchText : 'c_name' : 'c_id')
            "
          >
            <td class="col-sm-4">
              {{ genpopData.c_id }}
            </td>
            <td class="col-sm-4">
              {{
                genpopData.label_name ||
                  genpopData.eng_text ||
                  genpopData.c_name
              }}
            </td>
            <td class="col-sm-2">
              <label [hidden]="genpopData.showEditPorcentage">{{
                genpopData.perc || 'NA'
              }}</label>
              <input
                #inputPercent
                value="{{ genpopData.perc }}"
                type="number"
                [hidden]="!genpopData.showEditPorcentage"
              />
              <i
                class="fa fa-check-circle-o"
                aria-hidden="true"
                [hidden]="!genpopData.showEditPorcentage"
                (click)="
                  changePercentageValues(inputPercent.valueAsNumber, genpopData)
                "
              ></i>
              <i
                class="fa fa-times-circle-o"
                aria-hidden="true"
                [hidden]="!genpopData.showEditPorcentage"
                (click)="genpopData.showEditPorcentage = false"
              ></i>
              <i
                [hidden]="
                  genpopData.perc === undefined || genpopData.showEditPorcentage
                "
                class="fa fa-pencil-square-o editBtn"
                aria-hidden="true"
                (click)="genpopData.showEditPorcentage = true"
              ></i>
            </td>
            <td class="col-sm-2">
              <label [hidden]="genpopData.showEditMultiplier">{{
                genpopData.price_multiplier
              }}</label>
              <input
                #inputPriceMultiplier
                value="{{ genpopData.price_multiplier }}"
                type="number"
                [hidden]="!genpopData.showEditMultiplier"
              />
              <i
                class="fa fa-check-circle-o"
                aria-hidden="true"
                [hidden]="!genpopData.showEditMultiplier"
                (click)="
                  changeMultiValue(
                    inputPriceMultiplier.valueAsNumber,
                    genpopData
                  )
                "
              ></i>
              <i
                class="fa fa-times-circle-o"
                aria-hidden="true"
                [hidden]="!genpopData.showEditMultiplier"
                (click)="genpopData.showEditMultiplier = false"
              ></i>
              <i
                [hidden]="
                  genpopData.price_multiplier === undefined ||
                  genpopData.showEditMultiplier
                "
                class="fa fa-pencil-square-o editBtn"
                aria-hidden="true"
                (click)="genpopData.showEditMultiplier = true"
              ></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
