<div class="row mb-4">
  <div class="col-sm-6 col-md-4">
    <div class="form-group">
      <label class="mb-2">COMPANY</label>
      <div class="d-flex">
        <ps-ui-select-dropdown
          class="flex-fill"
          style="max-width: 380px"
          [dropdownItems]="buyers"
          labelAlignment="start"
          [field]="'name'"
          [label]="selectedCompany?.name || 'All'"
          [disabled]="isLoading"
          (selectedItem)="setSelectedCompany($event)"
        ></ps-ui-select-dropdown>
      </div>
    </div>
  </div>
  <div class="col-sm-6 col-md-3">
    <div class="form-group new-chnges">
      <label class="pl-1 mb-2">MONTH</label>
      <div class="d-flex align-items-center">
        <select
          class="form-control"
          style="color: #333"
          [disabled]="isLoading"
          [(ngModel)]="selectMonth"
          (ngModelChange)="checkIfPoOrProject()"
        >
          <option *ngFor="let month of months" [value]="month">
            {{ month }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div class="col-sm-6 col-md-3">
    <div class="form-group new-chnges">
      <label class="pl-1 mb-2">YEAR</label>
      <div class="d-flex align-items-center">
        <select
          class="form-control"
          style="color: #333"
          [disabled]="isLoading"
          [(ngModel)]="selectYear"
          (ngModelChange)="checkIfPoOrProject()"
        >
          <option *ngFor="let year of years" [value]="year">
            {{ year }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div *ngIf="isPoOrProject" class="col-sm-6 col-md-2">
    <div class="form-group new-chnges">
      <label class="pl-1 mb-2">DATE</label>
      <div class="d-flex align-items-center">
        <select
          class="form-control"
          style="color: #333"
          [(ngModel)]="selectDate"
        >
          <option *ngFor="let date of dates" [value]="date">
            {{ date }}
          </option>
        </select>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 col-sm-12 col-md-12 invoice-operations">
    <button
      id="dashboard"
      type="button"
      psUiButton
      size="small"
      color="primary"
      [routerLink]="['/dashboard/']"
    >
      Back to Dashboard
    </button>
    <button
      id="downloadInvoice"
      type="button"
      psUiButton
      size="small"
      color="primary"
      (click)="downloadInvoice()"
    >
      Download
    </button>
    <button
      id="downloadSupplierPdf"
      type="button"
      psUiButton
      size="small"
      color="primary"
      (click)="downloadSupplierPdf()"
    >
      Download Supplier PDF
    </button>
    <button
      id="downloadBuyerXlsx"
      type="button"
      psUiButton
      size="small"
      color="primary"
      (click)="downloadBuyerXlsx()"
    >
      Download Buyer XLSX
    </button>
    <button
      id="downloadSupplierXlsx"
      type="button"
      psUiButton
      size="small"
      color="primary"
      (click)="downloadSupplierXlsx()"
    >
      Download Supplier XLSX
    </button>

    <button
      *ngIf="!isABuyer"
      id="downloadRewardCsv"
      type="button"
      psUiButton
      size="small"
      color="primary"
      (click)="downloadSupplierRewardReport()"
    >
      Additional Rewards Report
    </button>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 col-sm-12 col-md-12 invoice-operations">
    <button
      *ngIf="isABuyer"
      id="regenerateInvoice"
      type="button"
      psUiButton
      size="small"
      color="primary"
      (click)="regenerateInvoice()"
    >
      Regenerate the Invoice
    </button>
  </div>
</div>
