'use-strict';

export const messages = Object.freeze({
  WARNING_MESSAGE: {
    NO_FILE: 'Please select a file before proceeding.',
    INCORRECT_FILE_FORMAT:
      'This file is in the incorrect format. Please see the example file.',
    INCORRECT_VALUE: 'Invalid Input! Please enter value between 0-365',
    BLOCKED_COUNTRY:
      'This Question is no longer available due to the Country being Blocked on your account.  If you feel this is a mistake, please contact your Account Manager.',
  },
  SUCCESS_MESSAGE: {
    FILE_ADDED: 'File added successfully',
    DQ_QUESTIONS_UPLOADED: 'File uploaded successfully',
  },
  ERROR_MESSAGE: {
    ANSWERS_OBJECT_MISMATCH_PART_ONE: `The condition code used in `,
    ANSWERS_OBJECT_MISMATCH_PART_TWO: ` are not the same of the last entered.
    Change them and make sure to use the same set of codes.`,
    DQ_UPLOAD_UNKNOWN_ERROR: 'Error occurred while processing the file',
  },
});

export const fileConstants = Object.freeze({
  FILE: `/assets/templates/AdvTargetingImportFile.xlsx`,
  FILE_TYPE: 'xlsx',
  FORM_FILE_NAME: 'advance_questions',
});

export const Constants = Object.freeze({
  CLASS_OPTIONS: [
    { key: 1, value: 'Core' },
    { key: 2, value: 'Extended' },
    { key: 3, value: 'Custom' },
    { key: 5, value: 'Data Quality' },
    { key: 6, value: 'Segmentation' },
    { key: 7, value: 'Training Data' },
  ],
  QUESTION_TYPE_OPTIONS: [
    { key: 1, value: 'Single Punch' },
    { key: 3, value: 'Multi Punch' },
    { key: 4, value: 'Range' },
    { key: 5, value: 'Input' },
    { key: 7, value: 'Open Ended' },
  ],
  RANDOM_OPTIONS: [
    { key: true, value: 'On' },
    { key: false, value: 'Off' },
  ],
  ANSWER_ORDER_OPTIONS: [
    { key: 1, value: 'Alpha/Numeric' },
    { key: 2, value: 'Random' },
    { key: 3, value: 'Custom' },
  ],
  DP_OPTIONS: [
    { key: false, value: 'False' },
    { key: true, value: 'True' },
  ],
  DEPENDENCY_OPTIONS: [
    { key: false, value: 'Off' },
    { key: true, value: 'On' },
  ],
  RANGE_UNITS_OPTIONS: [
    { key: 311, value: 'Years' },
    { key: 312, value: 'Months' },
    { key: 313, value: 'Days' },
    { key: 314, value: 'Hours' },
    { key: 401, value: 'Movies' },
    { key: 402, value: 'TV Hours' },
    { key: 403, value: 'Nights' },
    { key: 404, value: 'Trips' },
    { key: 501, value: 'Input Age' },
  ],
  DEPENDENCY_CONDITION: [
    { key: 'in', value: 'In' },
    { key: 'nin', value: 'Not in' },
  ],
  QUESTION_LIBRARY_PAGE_LIMIT: 10,
  QUESTION_PLACEHOLDER: [
    'Please select all that apply',
    'Please select one',
    'Please answer the following question',
    'Select all that apply',
    'Select one',
    'Type answer here',
  ],
  QUESTION_TYPE: {
    SINGLEPUNCH: 1,
    MULTIPUNCH: 3,
    RANGE: 4,
    INPUT: 5,
    OPENENDED: 7,
  },
  QUALIFICATION_TYPE: [
    { key: '', value: 0 },
    { key: 'singlepunch', value: 1 },
    { key: 'singlepunch-alt', value: 2 },
    { key: 'multipunch', value: 3 },
    { key: 'range', value: 4 },
    { key: 'input', value: 5 },
    { key: 'dependent', value: 6 },
    { key: 'openended', value: 7 },
  ],
  RANGE_UNITS: {
    INPUT_AGE: 1,
    YEARS: 2,
    MONTHS: 3,
    DAYS: 4,
    HOURS: 5,
    MOVIES: 6,
    TV_HOURS: 7,
    NIGHTS: 8,
    TRIPS: 9,
  },
  MAPPED_CLASS_TYPE: {
    CORE: 1,
    EXTENDED: 2,
    CUSTOM: 3,
    DATA_QUALITY: 5,
    SEGMENTATION: 6,
  },
  CLASS: {
    DATA_QUALITY: 'Data Quality',
  },
  SPECIAL_QUESTION_IDS: {
    PRIVACY_POLICY_QUAL_ID: 99996,
  },
  MODAL: {
    DELETE_QUESTION: {
      TEXT: 'Are you sure you want to delete this Question?',
    },
    DELETE_LAST_LOCALIZATION: {
      TEXT: 'Deleting last Localization will also delete the Question. Are you sure you want to proceed?',
    },
    DELETE_LOCALIZATION: {
      TEXT: 'Are you sure you want to delete this Localization?',
    },
  },
  LOCALIZATION: {
    ANSWERS: {
      DEFAULT_CONDITION_CODE: 111,
      ANCHOR_CONDITION_CODE: {
        MIN: 990,
      },
      TEXT_DELIMITER: /[\n]+/g,
    },
  },
  POSITION_OPTIONS: [
    {
      value: 'Advance Targeting search view',
      key: 0,
      type: 'advance',
    },
    { value: 'Front View', key: 1, type: 'front' },
    { value: 'Location', key: 2, type: 'location' },
  ],
  ALL_COMPONENT_OPTIONS: [
    { value: 'Advance View', key: 0, type: 'advance' },
    { value: 'Dropdown', key: 1, type: 'front' },
    { value: 'Upload', key: 2, type: 'location' },
    { value: 'Search', key: 3, type: 'location' },
    { value: 'Grid', key: 5, type: 'location' },
  ],
  ADVANCE_DEFAULT: { value: 'Advance View', key: 0, type: 'advance' },
  DATA_QUALITY: {
    EMPTY_ANSWER_WARNING: {
      text: 'Warning: Please select Low Quality Response(s) to continue',
    },
    EMPTY_ANSWER_SELECT: {
      text: 'No Options Available',
    },
    AVAILABLE_ANSWER_SELECT: {
      text: 'Select',
    },
    QUESTION_CATEGORY: 'Data Quality',
  },
  LANGUAGES_TRANSLATE_CODE: {
    ENGLISH: 'en',
  },
  QUESTION_CATEGORY: {
    CORE: 'simple',
    EXTENDED: 'advance',
    CUSTOM: 'advance',
    DEPENDENT: 'dependent',
    DATA_QUALITY: 'data_quality',
    SEGMENTATION: 'advance',
    TRAINING_DATA: 'training_data',
  },
});

export const fileDQConstants = Object.freeze({
  FILE: `/assets/templates/DQTargetingImportFile.xlsx`,
  FILE_TYPE: 'xlsx',
  FORM_FILE_NAME: 'data_quality_questions',
});
