import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  GetQualificationResponse,
  GetGenpopDataResponse,
  UpdatePercentageQualificationGenpopResponse,
  UpdatePercentageQualificationGenpopGlobalResponse,
  GetEngQualificationTextResponse,
} from './genpop-setting.interface';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class GenpopSettingService {
  private readonly _pureSpectrumBaseUrl = environment.pureSpectrum.url;
  private readonly _feasibilityBaseUrl =
    environment.pureSpectrum.feasibilityUrl;

  constructor(private _http: HttpClient) {}

  getQualifications(locale: string, pageNumber: number) {
    let params = new HttpParams();
    params = params.set('localization', locale);
    params = params.set('page', pageNumber);
    params = params.set('format', true);

    return this._http
      .get<GetQualificationResponse>(
        `${this._pureSpectrumBaseUrl}/suppliers/v2/attributes`,
        { observe: 'response', params }
      )
      .pipe(
        map((response) => {
          return {
            pages: response.headers.get('total_pages'),
            qualifications: response?.body?.qual_attributes,
          };
        })
      );
  }

  getGenpopDataByQualificationCode(
    coutryCode: string,
    languageCode: string,
    qualificationCode: number,
    page: number = 1
  ): Observable<GetGenpopDataResponse[]> {
    return this._http.get<GetGenpopDataResponse[]>(
      `${this._feasibilityBaseUrl}/feasibility/v1/genpop/${coutryCode}/${languageCode}?qualificationCode=${qualificationCode}&page=${page}`
    );
  }

  updatePercentageQualificationGenpop(
    coutryCode: string,
    languageCode: string,
    qualificationCode: number,
    conditionCode: number,
    percentageValue: number
  ): Observable<GetGenpopDataResponse[]> {
    const payload = {
      op: 'replace',
      path: `/${qualificationCode}/${conditionCode}`,
      value: percentageValue,
    };
    return this._http.patch<GetGenpopDataResponse[]>(
      `${this._feasibilityBaseUrl}/feasibility/v1/genpop/${coutryCode}/${languageCode}`,
      payload
    );
  }

  updateGenpopFile(file: FormData) {
    return this._http.patch<UpdatePercentageQualificationGenpopResponse>(
      `${this._feasibilityBaseUrl}/feasibility/v1/genpop/upload`,
      file
    );
  }

  updateGenpopGlobalFile(file: FormData) {
    return this._http.post<UpdatePercentageQualificationGenpopGlobalResponse>(
      `${this._feasibilityBaseUrl}/feasibility/v1/genpop/bulk/upload`,
      file
    );
  }

  updateGenpopDocument(
    countryCode: string,
    languageCode: string,
    payload: GetGenpopDataResponse
  ) {
    return this._http.put(
      `${this._feasibilityBaseUrl}/feasibility/v1/genpop/${countryCode}/${languageCode}`,
      payload
    );
  }

  updateQualificationConditionForV2(genpopData: any) {
    return this._http.patch(
      `${this._feasibilityBaseUrl}/feasibility/v2/genpop`,
      genpopData
    );
  }

  bulkUpdateBaseMultiplierForV2(baseMultplierData: any) {
    return this._http.patch(
      `${this._feasibilityBaseUrl}/feasibility/v2/genpop/base-multiplier-update`,
      baseMultplierData
    );
  }

  getQualificationsEnglishText(
    coutryCode: string,
    qualificationCode: number
  ): Observable<GetEngQualificationTextResponse> {
    return this._http.get<any>(
      `${this._pureSpectrumBaseUrl}/getEngQualificationDataByCountryAndId/${coutryCode}/${qualificationCode}`
    );
  }

  searchGenpopData(
    countryCode = '',
    LanguageCode = '',
    searchText = ''
  ): Observable<GetGenpopDataResponse> {
    return this._http.get<any>(
      `${this._feasibilityBaseUrl}/feasibility/v1/genpop/${countryCode}/${LanguageCode}/search?searchText=${searchText}`
    );
  }
}
