<div class="question-header" *ngIf="isEditMode">
  <h2>{{ questionData.qualification_code }} | {{ questionData.desc }}</h2>
  <button
    class="btn btn-danger btn-common"
    *ngIf="
      isUserHasQuestionLibraryAccess &&
      isEditMode &&
      !isEditingDisabled &&
      !isSegmentation
    "
    (click)="confirmAndDeleteQuestion()"
  >
    Delete Question
  </button>
</div>
<div class="row">
  <div class="tab-panel">
    <form class="question_form" [formGroup]="form">
      <div class="form-group" *psHasRole="'operator'">
        <label>Hide Question</label>
        <div class="setting-info">
          <i class="fa fa-info-circle" aria-hidden="true"></i>
          <p>
            This setting will hide the Question from Buyers during survey setup
            and in the Question Library, but the Question will remain active.
          </p>
        </div>
        <ps-ui-slider
          class="float-end"
          [selected]="form.controls.hideQuestion.value"
          (selectedChange)="form.controls.hideQuestion.setValue($event)"
        >
        </ps-ui-slider>
      </div>
      <div class="form-group" *psHasRole="'operator'">
        <div
          *ngIf="
            form.controls.class.value.key !==
            CONSTANTS.MAPPED_CLASS_TYPE.DATA_QUALITY
          "
        >
          <label>Company</label>
          <div
            class="position_relative"
            *ngIf="
              form.controls.class.value.key !==
                CONSTANTS.MAPPED_CLASS_TYPE.CUSTOM && !isSegmentationClass()
            "
          >
            <ps-ui-select-dropdown
              [dropdownItems]="companies"
              [field]="'name'"
              [enableSearch]="true"
              labelNone="--Select Company--"
              [label]="form.controls.company?.value[0].name"
              (selectedItem)="selectCompany($event)"
            ></ps-ui-select-dropdown>
          </div>
          <div
            class="position_relative"
            *ngIf="
              form.controls.class.value.key ===
                CONSTANTS.MAPPED_CLASS_TYPE.CUSTOM || isSegmentationClass()
            "
          >
            <ng-select
              class="custom"
              placeholder="--Select Company--"
              [clearable]="false"
              [multiple]="true"
              [items]="companies"
              [closeOnSelect]="false"
              bindLabel="name"
              [(ngModel)]="selectedCompanies"
              [ngModelOptions]="{ standalone: true }"
              (change)="selectCompanies($event)"
              [disabled]="isSegmentationClass() && isEditMode"
            >
            </ng-select>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>Class <span class="required">*</span></label>
        <div>
          <ps-ui-select-dropdown
            [dropdownItems]="classValues"
            [field]="'value'"
            [enableSearch]="false"
            [label]="form.controls.class?.value?.value"
            [disabled]="isEditMode || userType === 'buyer'"
            [ngClass]="{
              'not-allowed-cursor': isEditMode || userType === 'buyer'
            }"
            (selectedItem)="form.controls.class.setValue($event)"
          ></ps-ui-select-dropdown>
        </div>
      </div>
      <div class="form-group">
        <label>Recency (Day(s))</label>
        <input
          type="number"
          name="desc"
          class="form-control input-text"
          formControlName="recency"
          placeholder="0-365"
          [attr.disabled]="isEditingDisabled ? true : null"
          [ngClass]="{ 'not-allowed-cursor': isEditingDisabled }"
          [value]=""
          (change)="validateRecency($event)"
        />
      </div>
      <div class="form-group">
        <label>Question Description <span class="required">*</span></label>
        <input
          type="text"
          name="desc"
          class="form-control input-text"
          formControlName="desc"
          placeholder="Question Description"
        />
      </div>
      <div class="form-group">
        <label>Question Category <span class="required">*</span></label>
        <ng-select
          class="custom"
          placeholder="Category 1, Category 2"
          notFoundText="No Category Available"
          [clearable]="false"
          [multiple]="true"
          [clearSearchOnAdd]="true"
          [items]="categories"
          formControlName="category"
          (change)="selectCategory($event)"
          [addTag]="canUserAddCategory"
          addTagText="Add"
        >
        </ng-select>
      </div>
      <div class="form-group">
        <label>Question Type <span class="required">*</span></label>
        <div>
          <ps-ui-select-dropdown
            [dropdownItems]="questionTypes"
            [field]="'value'"
            [enableSearch]="false"
            [label]="form.controls.type?.value?.value"
            [disabled]="isEditMode"
            [ngClass]="{ 'not-allowed-cursor': isEditMode }"
            (selectedItem)="form.controls.type.setValue($event)"
          ></ps-ui-select-dropdown>
        </div>
      </div>
      <div class="form-group">
        <label>Answer Order</label>
        <div>
          <ps-ui-select-dropdown
            [dropdownItems]="answerOrder"
            [label]="form.controls.answerOrder.value.value"
            [enableSearch]="false"
            [field]="'value'"
            (selectedItem)="form.controls.answerOrder.setValue($event)"
          >
          </ps-ui-select-dropdown>
        </div>
      </div>
      <div class="form-group">
        <label>Question Tags</label>
        <ng-select
          class="custom"
          appendTo="body"
          bindLabel="name"
          placeholder="Tags 1, Tags 2"
          addTagText="Add"
          [clearable]="false"
          [isOpen]="false"
          [addTag]="true"
          [multiple]="true"
          [clearSearchOnAdd]="true"
          formControlName="tags"
        >
        </ng-select>
      </div>
      <div class="form-group">
        <label>Stem 1</label>
        <button
          *ngIf="isUserHasQuestionLibraryAccess"
          type="button"
          class="btn btn-default btn-common add-resp-id-btn"
          [ngClass]="{ 'not-allowed-cursor': stem1BtnDisabled }"
          [disabled]="stem1BtnDisabled"
          (click)="clickRespId('stem1')"
        >
          + Add respId
        </button>
        <input
          type="text"
          autocomplete="off"
          formControlName="stem1"
          name="stem1"
          class="form-control input-text"
          (input)="checkAndSetButtonDisabled('stem1')"
          placeholder="that work in one of the following fields: %%respId%%"
        />
      </div>
      <div class="form-group">
        <label>Stem 2</label>
        <button
          *ngIf="isUserHasQuestionLibraryAccess"
          type="button"
          class="btn btn-default btn-common add-resp-id-btn"
          [ngClass]="{ 'not-allowed-cursor': stem2BtnDisabled }"
          [disabled]="stem2BtnDisabled"
          (click)="clickRespId('stem2')"
        >
          + Add respId
        </button>
        <input
          type="text"
          formControlName="stem2"
          autocomplete="off"
          name="stem2"
          class="form-control input-text"
          (input)="checkAndSetButtonDisabled('stem2')"
          placeholder="Stem 2"
        />
      </div>
      <div class="form-group">
        <label>Question Buyer Text</label>
        <button
          *ngIf="isUserHasQuestionLibraryAccess"
          type="button"
          class="btn btn-default btn-common add-resp-id-btn"
          [ngClass]="{ 'not-allowed-cursor': buyerTextBtnDisabled }"
          [disabled]="buyerTextBtnDisabled"
          (click)="clickRespId('buyertext')"
        >
          + Add respId
        </button>
        <input
          type="text"
          id="buyertext"
          autocomplete="off"
          formControlName="buyertext"
          name="buyertext"
          class="form-control input-text"
          (input)="checkAndSetButtonDisabled('buyertext')"
          placeholder="I’m looking for people that work in one of the following fields: %%respId%%"
        />
      </div>
      <div class="form-group">
        <label>Create Survey Question Order</label>
        <div class="setting-info">
          <i class="fa fa-info-circle" aria-hidden="true"></i>
          <p>
            This allows the Operator to decide the order to show the
            Qualification box in the Survey Creation process
          </p>
        </div>
        <input
          type="number"
          class="form-control input-text"
          name="order"
          placeholder="0"
          formControlName="create_survey_question_order"
        />
      </div>

      <div
        class="form-group"
        *ngIf="
          form.controls.type?.value?.key === this.CONSTANTS.QUESTION_TYPE.RANGE
        "
      >
        <label>Units</label>
        <ps-ui-select-dropdown
          [dropdownItems]="rangeUnits"
          [field]="'value'"
          [enableSearch]="false"
          [ngClass]="{ 'not-allowed-cursor': isEditMode }"
          [disabled]="isEditMode"
          formControlName="units"
        ></ps-ui-select-dropdown>
      </div>
      <ps-ui-accordion
        [closeOthers]="false"
        [arrowToggleOnly]="false"
        class="accordian-new-localization"
      >
        <ps-ui-accordion-section
          class="accordian-new-localization-1"
          *ngFor="
            let localization of localizations;
            let i = index;
            trackBy: getLocalizationId
          "
        >
          <ng-template psUiAccordionTitle>
            <div class="accordian-inner-span">
              <span>{{
                localization.locale.split('_').join(' - ')
                  | localeShortCode : ' - ' : 'UK' : 'GB'
              }}</span>
              <span
                >Total Answered :
                {{
                  localization.stats && localization.stats.total
                    ? localization.stats.total
                    : 0
                }}</span
              >
            </div>
            <span class="accordian-inner-span-254">
              <button
                class="btn btn-standard copy-txt-btn"
                (click)="copyQuestionText(i, $event)"
              >
                <i class="fa fa-copy pad-right-5" aria-hidden="true"></i>
                Copy Question Text
              </button>
            </span>

            <span
              *ngIf="isUserHasQuestionLibraryAccess && !isEditingDisabled"
              class="accordian-inner-span-2"
              ><button
                class="btn btn-primary tooltip-visible"
                (click)="cloneLocalization(i, $event)"
              >
                <div class="tootip-setting-info">
                  <p class="tooltip-text">Duplicate Localization</p>
                </div>
                <i class="fa fa-clone" aria-hidden="true"></i></button
            ></span>
            <span
              *ngIf="
                isUserHasQuestionLibraryAccess &&
                !isEditingDisabled &&
                !isSegmentation
              "
              class="accordian-inner-span-3 red-danger"
              ><button
                class="btn btn-danger"
                (click)="checkAndDeleteLocalization(i, $event)"
              >
                <i class="fa fa-close" aria-hidden="true"></i></button
            ></span>
          </ng-template>
          <ng-template psUiAccordionContent>
            <ps-question-localization
              [questionCategory]="form.controls.category.value"
              [questionType]="form.controls.type.value.key"
              [class]="form.controls.class.value.key"
              [localizationAdded]="localization"
              [(localizations)]="localizations"
              [isEditLocalizationMode]="true"
              [isSegmentation]="isSegmentation"
              [isPrivacyPolicyQuestion]="isPrivacyPolicyQuestion"
              [index]="i"
            ></ps-question-localization>
          </ng-template>
        </ps-ui-accordion-section>
      </ps-ui-accordion>
      <ps-ui-accordion
        [closeOthers]="false"
        [arrowToggleOnly]="false"
        class="accordian-new-localization"
      >
        <ps-ui-accordion-section
          [isExpanded]="isCloneLocalization"
          class="accordian-new-localization-2"
        >
          <ng-template psUiAccordionTitle>
            <button
              *ngIf="isUserHasQuestionLibraryAccess"
              class="new-localization-btn"
              (click)="newLocalizationToggle()"
            >
              + New Localization
            </button>
          </ng-template>
          <ng-template psUiAccordionContent>
            <ps-question-localization
              [questionCategory]="form.controls.category.value"
              [questionType]="form.controls.type.value.key"
              [class]="form.controls.class.value.key"
              [(localizations)]="localizations"
              [clonedLocalization]="clonedLocalization"
              [isEditMode]="isEditMode"
              [questionId]="questionData.qualification_code"
              [isEditLocalizationMode]="false"
              [isPrivacyPolicyQuestion]="isPrivacyPolicyQuestion"
            ></ps-question-localization>
          </ng-template>
        </ps-ui-accordion-section>
      </ps-ui-accordion>
      <button
        *ngIf="
          isUserHasQuestionLibraryAccess && (!isEditMode || !isEditingDisabled)
        "
        class="btn btn-default save_changes-btn btn-right"
        (click)="saveChanges()"
      >
        Save Changes
      </button>
    </form>
  </div>
</div>
