'use strict';

export const Constants = Object.freeze({
  MANAGE_SUPPLIER_TABS: [
    {
      title: 'EXCLUSION SETTINGS',
      active: true,
    },
    {
      title: 'REDIRECT URL',
      active: false,
    },
    {
      title: 'VARIABLE MAPPING',
      active: false,
    },
    {
      title: 'NOTIFICATION STATUS',
      active: false,
    },
    {
      title: 'HASHING',
      active: false,
    },
    {
      title: 'S2S CALLBACKS',
      active: false,
    },
    {
      title: 'MICRO SURVEYS',
      active: false,
    },
  ],
  MAX_LOI_VALUE: 100,
});

export const messages = Object.freeze({
  WARNING_MESSAGE: {
    INCORRECT_LOI_VALUE: 'Max LOI should be lower than 101',
  },
  ERROR_MESSAGE: {
    EMPTY_HASH_KEY: 'Please enter valid hashkey',
  },
});
