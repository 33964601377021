<div class="hasing-tabs">
  <div class="text-center">
    <h4>Download Reports</h4>
  </div>
  <ps-ui-tab-group
    (activeIndexChange)="checkActiveTab($event)"
    *ngIf="isSupplier"
  >
    <ps-ui-tab *ngFor="let tab of tabs" [label]="tab.title"></ps-ui-tab>
  </ps-ui-tab-group>
</div>
<div [hidden]="activeTab.title !== 'TRANSACTION REPORT'">
  <div class="custom-reports">
    <h5 class="max-limit">
      <span
        >Max limit
        <div class="setting-info">
          <i class="fa fa-info-circle" aria-hidden="true"></i>
          <p class="info-style">
            There is a 700,000 respondent row limit on the report, please
            consider this when requesting your report. Also, please limit your
            search to a 7 day window.
          </p>
        </div>
      </span>
    </h5>
    <form class="report-form">
      <div class="form-group">
        <input
          class="form-control"
          type="text"
          name="daterangeInput"
          daterangepicker
          [options]="options"
          (selected)="selectedDate($event)"
        />
      </div>
      <div class="form-group">
        <div class="list-select">
          <ps-ui-select-dropdown
            id="multi-with-search"
            [multi]="true"
            [dropdownItems]="transactionStatus"
            labelAlignment="start"
            [field]="'name'"
            [allSelected]="true"
            (selectedItems)="selectedCmp($event)"
          ></ps-ui-select-dropdown>
        </div>
      </div>
      <div class="report-buttons text-center mt-6">
        <button
          class="btn submit-btn ps-sky"
          type="button"
          psUiButton
          size="small"
          color="primary"
          (click)="saveReportData()"
        >
          Submit
        </button>
        <button
          class="btn submit-btn ps-sky"
          id="dashboard"
          type="button"
          psUiButton
          size="small"
          color="primary"
          [routerLink]="['/dashboard/']"
        >
          Back to Dashboard
        </button>
      </div>
      <div class="button-group">
        <div class="margin-top-download">
          <button
            type="button"
            psUiButton
            *ngIf="isOperatorUser"
            class="btn submit-btn ps-sky"
            color="primary"
            name="submit"
            (click)="downloadLoginReport()"
          >
            Get User Activity Report
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="row report-table">
    <p class="report-table-p">
      * Reports will be available only for 3 days after creation
    </p>
    <ps-report-sort-table [dataList]="strReportsArr"> </ps-report-sort-table>
  </div>
</div>
<div [hidden]="activeTab.title !== 'STATEMENT REPORT'" class="supplier-tabs">
  <div class="row margin-div" *ngTemplateOutlet="reportsTemp"></div>
</div>
<div
  [hidden]="activeTab.title !== 'REVERSAL REPORT'"
  *ngIf="isSupplierUser"
  class="supplier-tabs"
>
  <div class="row margin-div" *ngTemplateOutlet="reportsTemp"></div>
</div>
<div
  [hidden]="activeTab.title !== 'REWARD REPORT'"
  *ngIf="isSupplierUser"
  class="supplier-tabs"
>
  <div class="row margin-div" *ngTemplateOutlet="reportsTemp"></div>
</div>
<ng-template #reportsTemp>
  <div class="row margin-div">
    <div class="col-md-6">
      <div class="form-group new-chnges">
        <label class="pl-1 mb-2">MONTH</label>
        <div class="d-flex align-items-center">
          <select
            class="form-control"
            style="color: #333"
            [(ngModel)]="selectMonth"
          >
            <option *ngFor="let month of months" [value]="month">
              {{ month }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group new-chnges">
        <label class="pl-1 mb-2">YEAR</label>
        <div class="d-flex align-items-center">
          <select
            class="form-control"
            style="color: #333"
            [(ngModel)]="selectYear"
          >
            <option *ngFor="let year of years" [value]="year">
              {{ year }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="report-btn text-center mt-6">
      <button
        class="btn submit-btn ps-sky col-md-4 margin-btn"
        type="button"
        psUiButton
        size="small"
        color="primary"
        (click)="getSupplierReport(activeTab.uniqueId)"
      >
        Submit
      </button>
      <button
        class="btn submit-btn ps-sky col-md-4"
        id="dashboard"
        type="button"
        psUiButton
        size="small"
        color="primary"
        [routerLink]="['/dashboard/']"
      >
        Back to Dashboard
      </button>
    </div>
  </div>
</ng-template>
<ps-footer-copyright></ps-footer-copyright>
