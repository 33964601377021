import { UserType } from '@purespectrum1/ui/marketplace/shared/types/user-type';
import { SurveyListing } from '../../shared/services/buyer-api/survey.interface';
import { SurveyStatus } from '../../utils/survey-status';
import { Constants, WIDGETS } from '../dashboard-constants';
import { Widget } from '../widget/widget';

export class DashboardSurveyTransform {
  constructor(
    public readonly surveys: any[],
    public readonly user: UserType,
    public readonly widgets: Widget[]
  ) {}

  public listing(): SurveyListing[] {
    return this.surveys.map((survey) => {
      const mapped = this.api(survey);
      return {
        ...mapped,
        surveyTitle: this.title(mapped),
        inProgress: this.progress(mapped),
        averageCpi: this.averageCpi(mapped),
        currentIR: this.currentIR(mapped),
        actualMargin: this.actualMargin(survey),
        countryLang: survey.survey_localization,
        survey_pause_threshold: survey.survey_pause_threshold,
        is_survey_pause_threshold_triggered:
          survey.is_survey_pause_threshold_triggered,
        epc: mapped.epc,
        margin: this.margin(survey),
        scpi: this.sCpi(survey),
        healthCheck: survey.healthCheck,
        company: survey.company,
        survey_launch_date: survey.survey_launch_date
          ? new Date(survey.survey_launch_date)
          : null,
        billing_id: survey.billing_id || '',
        ...(survey.mc && {
          mc: survey.mc,
        }),
        paceData: survey.pacing_data,
        is_aida_enabled: survey.is_aida_enabled,
      };
    });
  }

  public api(survey: any, mapped: any = {}) {
    let mapper: any =
      this.user === 'supplier'
        ? Constants.SUPPLIER_V2_SURVEY_MAP
        : Constants.BUYER_V2_SURVEY_MAP;

    for (const [key, value] of Object.entries(survey)) {
      if (value && typeof value === 'object' && mapper[key]) {
        this.api(value, mapped);
      } else {
        if (mapper[key]) {
          mapped[mapper[key]] = value;
        }
      }
    }

    return mapped;
  }

  public margin(survey: any): string {
    if (!survey?.is_enable_margin_override) {
      return 'N/A';
    }
    const margin = survey['margin_override']?.margin;
    return margin || 0;
  }

  public sCpi(survey: any): string {
    if (!survey?.is_enable_margin_override) {
      return 'N/A';
    }
    return survey?.scpi;
  }

  public title(survey: any): string {
    if (this.widgets.some((widget) => widget.key === WIDGETS.multiCountry)) {
      const parts = survey.surveyTitle.split('- MC');

      if (parts.length == 1) {
        return parts[0];
      }

      return `${parts[0]}- MC`;
    }

    return survey.surveyTitle;
  }

  public progress(survey: any): number | string {
    if (SurveyStatus[survey.status] === 'Draft') {
      return 'NA';
    }
    return survey.inProgress || 0;
  }

  public averageCpi(survey: any): number | string {
    if (SurveyStatus[survey.status] === 'Draft') {
      return 'NA';
    }

    if (survey.fielded > 0 || this.user === 'supplier') {
      return survey.averageCpi || 0;
    }

    return survey.aCpi || 0;
  }

  public currentIR(survey: any): number | string {
    if (SurveyStatus[survey.status] === 'Draft' || !survey.currentIR) {
      return 'NA';
    }

    return `${survey.currentIR}%`;
  }

  public actualMargin(survey: any): number {
    return survey.margin_override && survey.margin_override.margin
      ? survey.margin_override.margin
      : 0;
  }
}
