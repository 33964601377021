<div
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="20"
  (scrolled)="onScroll()"
  class="tableMakeover"
>
  <div class="table-responsive table-overflow">
    <table class="table">
      <thead>
        <th *ngFor="let tableHeader of tableHeaders" [class]="tableHeader.key">
          {{ tableHeader.column }}
        </th>
        <ng-container *psHasRole="['operator', 'buyer']">
          <th *ngFor="let widget of widgets">{{ widget.column }}</th>
        </ng-container>

        <th *ngIf="enabledDynamicDashboard; else manage" class="gear">
          <a (click)="openDashboardPreferenceModal()">
            <i class="fa fa-2x fa-gear"></i>
          </a>
        </th>

        <ng-template #manage>
          <th class="manage-label">Manage</th>
        </ng-template>
      </thead>
      <tbody *ngIf="surveys.length; else noSurveys">
        <ng-container *ngFor="let survey of surveys">
          <tr
            [ngClass]="{
              'widget-open':
                selectedWidget.selected && selectedWidget.for(survey)
            }"
          >
            <td
              [ngClass]="{
                'health-check-td':
                  tableHeader.key === SURVEY_LISTING_TABLE_HEADERS.HEALTH_CHECK
              }"
              *ngFor="let tableHeader of tableHeaders"
              [routerLink]="
                tableHeader.key === SURVEY_LISTING_TABLE_HEADERS.STATUS ||
                tableHeader.key === SURVEY_LISTING_TABLE_HEADERS.HEALTH_CHECK
                  ? ''
                  : (survey | surveyRouterLink)
              "
            >
              <ng-container [ngSwitch]="tableHeader.key">
                <ng-container
                  *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.STATUS"
                >
                  <div class="serveryStatus">
                    <button
                      class="menu-btn"
                      [psUiDropdownToggleFor]="surveysStatus"
                      [disabled]="checkDisableSurveyStatus(survey)"
                      [ngClass]="{
                        greyOutInvoiceButton: checkDisableSurveyStatus(survey)
                      }"
                    >
                      {{ surveyStatus[survey.status] }}
                    </button>
                    <ps-ui-dropdown
                      class="status-dropdown"
                      #surveysStatus="psUiDropdown"
                    >
                      <div *ngFor="let statusButton of statusButtons">
                        <button
                          psUiDropdownItem
                          *ngIf="
                            statusButton.condition.indexOf(survey.status) > -1
                          "
                          (click)="
                            openStatusModal(
                              survey.surveyId,
                              statusButton.value,
                              survey.billing_id
                            )
                          "
                        >
                          {{ statusButton.value }}
                        </button>
                      </div>
                    </ps-ui-dropdown>
                  </div>
                </ng-container>
                <ng-container
                  *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.SURVEY_TITLE"
                >
                  <a
                    class="survey-link sernewTitle"
                    title="{{ survey.surveyTitle }}"
                    [routerLink]="survey | surveyRouterLink"
                    >{{ title(survey.surveyTitle) }}</a
                  >
                </ng-container>
                <ng-container
                  *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.BUYER_ACCOUNT"
                >
                  <a
                    class="survey-link buyerv"
                    [routerLink]="survey | surveyRouterLink"
                    >{{ survey.buyerAccount }}</a
                  >
                </ng-container>
                <ng-container
                  *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.FIELDED"
                >
                  <a
                    class="survey-link"
                    [routerLink]="survey | surveyRouterLink"
                    >{{ survey.fielded | number }} |
                    {{ survey.goal | number }}</a
                  >
                </ng-container>
                <ng-container
                  *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.AVERAGE_CPI"
                >
                  <a
                    class="survey-link"
                    [routerLink]="survey | surveyRouterLink"
                    >{{
                      survey.averageCpi !== 'NA'
                        ? (survey.averageCpi
                          | currency
                            : (!isSupplier ? survey.currencySymbol : '$'))
                        : 'NA'
                    }}</a
                  >
                </ng-container>
                <ng-container *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.EPC">
                  <a
                    class="survey-link"
                    [routerLink]="survey | surveyRouterLink"
                    >{{
                      survey.epc !== undefined
                        ? (survey.epc | currency : survey.currencySymbol)
                        : 'NA'
                    }}</a
                  >
                </ng-container>
                <ng-container
                  *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.PROJECT_COST"
                >
                  <a
                    class="survey-link"
                    [routerLink]="survey | surveyRouterLink"
                    >{{
                      survey.current_cost | currency : survey.currencySymbol
                    }}</a
                  >
                </ng-container>
                <ng-container
                  *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.MARGIN"
                >
                  <a
                    class="survey-link"
                    [routerLink]="survey | surveyRouterLink"
                    >${{ survey[tableHeader.key] }}</a
                  >
                </ng-container>
                <ng-container *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.BANK">
                  <a
                    class="survey-link"
                    [routerLink]="survey | surveyRouterLink"
                    >${{ survey[tableHeader.key] }}</a
                  >
                </ng-container>
                <ng-container
                  *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.COUNTRY_CODE"
                >
                  <a
                    class="survey-link"
                    [routerLink]="survey | surveyRouterLink"
                  >
                    {{ survey.mc_count ? 'MC' : survey[tableHeader.key] }}
                  </a>
                </ng-container>
                <ng-container
                  *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.LAUNCH_DATE"
                >
                  <a
                    [ngClass]="{
                      'survey-link': survey.survey_launch_date,
                      'blank-date': !survey.survey_launch_date
                    }"
                    [routerLink]="survey | surveyRouterLink"
                  >
                    {{
                      survey.survey_launch_date
                        ? (survey.survey_launch_date
                          | date : 'yyyy/MM/dd HH:mm')
                        : '-'
                    }}
                  </a>
                </ng-container>
                <ng-container
                  *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.RECONCILED"
                >
                  <a
                    class="survey-link"
                    [routerLink]="survey | surveyRouterLink"
                    >{{ survey[tableHeader.key] ? 'YES' : 'NO' }}</a
                  >
                </ng-container>
                <ng-container
                  *ngSwitchCase="SURVEY_LISTING_TABLE_HEADERS.HEALTH_CHECK"
                >
                  <ps-health-check-widget
                    [survey]="survey"
                  ></ps-health-check-widget>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <a
                    class="survey-link"
                    [routerLink]="survey | surveyRouterLink"
                    >{{ survey[tableHeader.key] }}</a
                  >
                </ng-container>
              </ng-container>
            </td>
            <ng-container *psHasRole="['operator', 'buyer']">
              <td *ngFor="let widget of widgets">
                <ps-widget-button
                  *ngIf="widget.count(survey)"
                  [widget]="widget"
                  [count]="widget.count(survey)"
                  [active]="
                    selectedWidget.selected &&
                    selectedWidget.for(survey) &&
                    selectedWidget.key === widget.key
                  "
                  (tap)="onWidgetPressed($event, survey)"
                ></ps-widget-button>
              </td>
            </ng-container>
            <td class="align-right" *psHasRole="['operator', 'buyer']">
              <div class="dots-outer">
                <button
                  class="dotsv"
                  *ngIf="survey.status === SURVEY_STATUS.DRAFT"
                  (click)="
                    surveyDetails(
                      survey.surveyId,
                      survey.status,
                      survey.samplesType
                    )
                  "
                >
                  <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                </button>
                <button
                  class="dotsv"
                  [psUiDropdownToggleFor]="manageDropDown"
                  *ngIf="survey.status !== SURVEY_STATUS.DRAFT"
                  [disabled]="
                    (userType === 'operator' && !_isServiceBuyerLoggedIn) ||
                    userType === 'supplier'
                  "
                >
                  <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                </button>
                <ps-ui-dropdown
                  class="manage-dropdown"
                  #manageDropDown="psUiDropdown"
                  [canClose]="canClose"
                  (click)="close()"
                >
                  <button
                    psUiDropdownItem
                    (click)="
                      surveyDetails(
                        survey.surveyId,
                        survey.status,
                        survey.samplesType
                      )
                    "
                  >
                    Manage
                  </button>
                  <button
                    *ngIf="survey.samplesType !== RECONTACT_SURVEY_SAMPLE_TYPE"
                    psUiDropdownItem
                    (click)="cloneSurvey(survey.surveyId)"
                    psUiGtagEvent
                    trackOn="click"
                    action="create_survey"
                    category="Survey_Create"
                    [params]="{ event_label: 'Clone Button Clicked' }"
                  >
                    Clone
                  </button>
                  <ps-ui-dropdown
                    [toggleSubmenu]="true"
                    class="btn-group"
                    *ngIf="
                      enableTrafficChannel &&
                      survey.status === SURVEY_STATUS.LIVE
                    "
                  >
                    <button
                      type="button"
                      class="btn btn-primary dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      (click)="openDropdown()"
                    >
                      Traffic Channel
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <ps-ui-dropdown-item
                          (click)="
                            createTCSurvey(
                              'REPLICA',
                              'surveyListingPage',
                              survey.surveyId
                            )
                          "
                          >Replica</ps-ui-dropdown-item
                        >
                      </li>
                      <li>
                        <ps-ui-dropdown-item
                          (click)="
                            createTCSurvey(
                              'SPECIFIC AUDIENCE',
                              'surveyListingPage',
                              survey.surveyId
                            )
                          "
                          >Specific Audience</ps-ui-dropdown-item
                        >
                      </li>
                      <li>
                        <ps-ui-dropdown-item
                          (click)="
                            createTCSurvey(
                              'TARGETED LIST',
                              'surveyListingPage',
                              survey.surveyId
                            )
                          "
                          >Targeted List</ps-ui-dropdown-item
                        >
                      </li>
                    </ul>
                  </ps-ui-dropdown>

                  <button
                    psUiDropdownItem
                    (click)="downloadTransactionReport(survey.surveyId)"
                  >
                    Download Report
                  </button>
                </ps-ui-dropdown>
              </div>
              <span
                *ngIf="
                  survey.status === SURVEY_STATUS.DRAFT && tab.status !== 'all'
                "
                class="del-btn"
              >
                <button (click)="deleteSurvey(survey.surveyId, survey.status)">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </button>
              </span>
            </td>
            <td *psHasRole="'supplier'">
              <div class="dots-outer">
                <button class="dotsv">
                  <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                </button>
              </div>
            </td>
          </tr>
          <tr class="widget-row">
            <td
              [colSpan]="getColspan()"
              [ngClass]="{ 'expanded-hidden': !selectedWidget.for(survey) }"
            >
              <ps-widget-container
                [expanded]="
                  selectedWidget.selected && selectedWidget.for(survey)
                "
                [widget]="selectedWidget.widget"
                [survey]="survey"
              ></ps-widget-container>
            </td>
          </tr>
        </ng-container>
      </tbody>
      <ng-template #noSurveys>
        <tbody>
          <tr>
            <td [attr.colspan]="getColspan()">
              <p>No Data Available In Table</p>
            </td>
          </tr>
        </tbody>
      </ng-template>
    </table>
    <div class="table-next-loader" *ngIf="tableNextLoader"></div>
    <p>
      Showing 1 to {{ surveysLength() }}
      <!-- of {{ tab.count }} entries -->
    </p>
  </div>
</div>
