'use strict';
export const GlobalConstants = Object.freeze({
  BSEC: 'a70mx8',
  IMPORT_DECIPHER_LINK_REGEX: /\/import-decipher-survey$/,
});
export const UserType = {
  SERVICE_OPERATOR: 'serviceOperator',
  ADMIN: 'admin',
};

export const ServiceOperatorsLevels = [
  UserType.SERVICE_OPERATOR,
  UserType.ADMIN,
];

export const GT_MEASUREMENT_ID = 'G-SBXV6L2M5X';
