export const Constants = Object.freeze({
  SUPPLIER_FIELDS: [
    {
      section: 'GENERAL SETTINGS',
      features: [
        {
          name: 'Supplier Type',
          value: 'supplierType',
          desc: '',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Dynamic Profiler',
          value: 'dynamicProfiler',
          desc: '',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Privacy Policy',
          value: 'isPrivacyPolicyActive',
          desc: '',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Data Quality Questions',
          value: 'isDataQualityQuestionsActive',
          desc: '',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Routing',
          value: 'isAsrRedirectionEnable',
          desc: '',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Skip Screener',
          value: 'skipPreScreener',
          desc: '',
          hasAdditionalInfo: false,
          disabled: false,
        },
      ],
    },
    {
      section: 'MICROSURVEY_SETTINGS',
      features: [
        {
          name: 'Microsurvey',
          value: 'active',
          desc: '',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Show Microsurvey Settings',
          value: 'showMicrosurveySettings',
          desc: '',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Show Consent',
          value: 'showConsent',
          desc: '',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Default Currency Name',
          value: 'currencyName',
          desc: '',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Default Conversion Value',
          value: 'conversionValue',
          desc: '',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Reward Amount',
          value: 'rewardAmount',
          desc: '',
          hasAdditionalInfo: false,
          disabled: false,
        },
      ],
    },
  ],
});
