import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  UploadResponse,
  Question,
  ParentQuestions,
  QuestionPayload,
  GetQuestionApiResponse,
  Category,
  CategoryPayload,
  GetQuestionPayload,
} from './questions-library-services.interface';
import { Constants } from '../questions-library.constants';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class QuestionsLibraryService {
  private readonly _baseUrl = environment.pureSpectrum.url;
  constructor(private _http: HttpClient) {}

  getQuestions(
    page: number = 1,
    search: string = '',
    categories: string = '',
    limit: number = Constants.QUESTION_LIBRARY_PAGE_LIMIT
  ) {
    const payload: GetQuestionPayload = {
      page,
      limit,
      search,
      categories,
    };

    return this._http
      .post<Question[]>(`${this._baseUrl}/buyers/v3/attributes/list`, payload, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          return {
            total: response.headers.get('total'),
            questions: response.body as Question[],
          };
        })
      );
  }

  getQuestionById(questionId: number) {
    const url = `${this._baseUrl}/buyers/v3/attributes/${questionId}?stats=1&UI=1`;
    return this._http.get<GetQuestionApiResponse>(url);
  }

  uploadAdvanceQuestions(fdata: FormData, validateFile: boolean) {
    return this._http.post<UploadResponse>(
      `${this._baseUrl}/survey/uploadAdvQuestions?validateFile=${validateFile}`,
      fdata
    );
  }

  uploadDataQualityQuestions(data: FormData, validateFile: boolean) {
    return this._http.post<any>(
      `${this._baseUrl}/buyers/v3/attributes/question-file?validateFile=${validateFile}`,
      data
    );
  }

  getParentQuestions(local: any, dp: number = 0, dataQuality: number = 0) {
    return this._http
      .get<ParentQuestions>(
        `${this._baseUrl}/search/questions?locale=${local}&dp=${dp}&dq=${dataQuality}`
      )
      .pipe(
        map((response) => {
          return response.questions;
        })
      );
  }
  getChildQuestions(
    local: any,
    segmentationDependentQuestion: boolean = false
  ) {
    return this._http
      .get<ParentQuestions>(
        `${this._baseUrl}/search/questions?locale=${local}&segmentationDependentQuestion=${segmentationDependentQuestion}`
      )
      .pipe(
        map((response) => {
          return response.questions;
        })
      );
  }
  addQuestion(payload: QuestionPayload) {
    return this._http.post(`${this._baseUrl}/buyers/v3/attributes`, payload);
  }

  updateQuestion(questionId: number, payload: QuestionPayload) {
    return this._http.put(
      `${this._baseUrl}/buyers/v3/attributes/${questionId}`,
      payload
    );
  }

  deleteQuestion(questionId: number, localization: string = '') {
    let url = `${this._baseUrl}/buyers/v3/attributes/${questionId}`;
    if (localization) {
      url += `?localization=${localization}`;
    }
    return this._http.delete(url, {
      headers: {
        skipErrorInterceptor: 'true',
      },
    });
  }

  uploadOEQuestions(data: FormData) {
    return this._http.post(
      `${this._baseUrl}/buyers/v3/attributes/open-ended-bulk-upload`,
      data
    );
  }

  getCategories() {
    const url = `${this._baseUrl}/buyers/v3/attributes/categories/all`;
    return this._http.get<Category[]>(url);
  }

  saveCategories(payload: CategoryPayload[]) {
    return this._http.post<Category[]>(
      `${this._baseUrl}/buyers/v3/attributes/categories/add`,
      payload
    );
  }
}
