import { Component, OnInit } from '@angular/core';
import {
  EachCountry,
  EachLanguage,
} from '../../shared/interfaces/common-service.interface';
import { CommonService } from '../../shared/services/common-service/common-service';
import { CompanyService } from '../../shared/services/company/company.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  CompaniesResponse,
  CompanyResponseObject,
} from '../../shared/interfaces/company.interface';
import { SupplierPricingCardService } from './supplier-pricing-card-service/supplier-pricing-card.service';
import { tabConstants } from './supplier-pricing-card.constants';
import { SupplierPriceCard } from './supplier-pricing-card-service/supplier-pricing-card.interface';
@Component({
  selector: 'ps-supplier-pricing-card',
  templateUrl: './supplier-pricing-card.component.html',
  styleUrls: ['./supplier-pricing-card.component.css'],
})
export class SupplierPricingCardComponent implements OnInit {
  public countryList: Array<EachCountry> = [];
  public selectedCountry!: EachCountry;
  public languageList: Array<EachLanguage> = [];
  public buyerList: Array<CompanyResponseObject> = [];
  public manualRateCardUploaded: boolean = false;
  public supplierPriceCard: SupplierPriceCard = {} as SupplierPriceCard;
  public currentTab: string = '';
  public isSet!: number;
  countryId: number = 1;
  languageId: number = 1;
  buyerId: number = 0;
  private _tab: Array<string> = [];
  form!: FormGroup;

  constructor(
    private _commonServices: CommonService,
    private _companyServices: CompanyService,
    private _pricingCardService: SupplierPricingCardService,
    private _fb: FormBuilder
  ) {
    this._tab = tabConstants.TAB;
  }

  ngOnInit(): void {
    this._generateForm();
    this._getCountries();
    this._getBuyers();
    this._supplierResponse();
  }
  private _generateForm() {
    this.form = this._fb.group({
      country: ['United States'],
      language: ['English'],
      buyer: ['All'],
    });
  }

  private _getCountries() {
    const addAllOption = false;
    this._commonServices
      .getCountries(addAllOption)
      .subscribe((res: Array<EachCountry>) => {
        this.countryList = res;
        this.languageList = res[0].lang;
      });
  }

  private _getLanguages(country: EachCountry) {
    this.languageList = country.lang;
  }

  private _getBuyers() {
    const isSpplr: boolean = false;
    const isBuyr: boolean = true;
    const addAllOption: boolean = true;
    this._companyServices
      .getAllBuyers({ isSpplr, isBuyr }, addAllOption)
      .subscribe((res: CompaniesResponse) => {
        this.buyerList = res.companies;
      });
  }

  public activeTab(tabName: string) {
    if (tabName == this._tab[0]) {
      this.isSet = 1;
    } else if (tabName == this._tab[1]) {
      this.isSet = 2;
    }
  }

  private _supplierResponse() {
    this._pricingCardService
      .getSupplier(this.languageId, this.countryId, this.buyerId)
      .subscribe((res) => {
        this.currentTab = res.pricingMethod;
        this.supplierPriceCard = res.supplier[0];
        this.isSet = 1;
        this.manualRateCardUploaded = false;
        if (res.pricingMethod == this._tab[1]) {
          this.isSet = 2;
          this.manualRateCardUploaded = true;
        }
        if (res.pricingMethod == this._tab[0]) {
          this.manualRateCardUploaded = true;
        }
      });
  }

  public selectCountry(country: EachCountry) {
    this.countryId = country.id;
    this.form.controls.country.setValue(country.name);

    this._getLanguages(country);
    const defaultLanguage = country.lang[0];
    this.selectLanguage(defaultLanguage);
  }

  public selectLanguage(language: EachLanguage) {
    if (language?.id) {
      this.languageId = language.id;
    }
    this.form.controls.language.setValue(language.name);
    this._supplierResponse();
  }

  public selectBuyerCounterParty(buyerCounterParty: CompanyResponseObject) {
    this.buyerId = buyerCounterParty.id;
    this.form.controls.buyer.setValue(buyerCounterParty.name);
    this._supplierResponse();
  }

  public cancelManualRateCard() {
    this._supplierResponse();
  }
}
