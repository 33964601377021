import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalService } from '@purespectrum1/ui/modal';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { BuyerService } from '../../shared/services/buyer/buyer.service';
import { of, Subscription, forkJoin } from 'rxjs';
import {
  Constants,
  HIDDEN_CONFIGS_FOR_PRODUCTION,
  blockedCountriesFeatureIndex,
  blockedCountriesSectionIndex,
  configFeaturesDocLink,
} from './config-settings-constants';
import { BuyerConfigService } from '../buyer-config/buyer.config.service';
import { EnableNightcrawlerModalComponent } from './enable-nightcrawler-modal/enable-nightcrawler-modal.component';
import { RnCredentialsModalComponent } from './rn-credentials-modal/rn-credentials-modal.component';
import { EnableDecipherModalComponent } from './enable-decipher-modal/enable-decipher-modal.component';
import { notifyMessage } from '../../constants/notify-message';
import { environment } from '../../../environments/environment';
import { EnableRealTimeNotificationModalComponent } from './enable-real-time-notification-modal/enable-real-time-notification-modal.component';
import { AcitivityService } from '../../shared/services/activity/activity.service';
import { catchError } from 'rxjs/operators';
import { CommonService } from '../../shared/services/common-service/common-service';
import { BuyerSettingsService } from '../../buyer/buyer-settings-service/buyer-settings-service.service';
import { EachCountry } from '../../shared/interfaces/common-service.interface';
import { UpdateObj } from '../../buyer/buyer-settings-service/buyer-settings-service.interface';

@Component({
  selector: 'ps-config-settings',
  templateUrl: './config-settings.component.html',
  styleUrls: ['./config-settings.component.css'],
})
export class ConfigSettingsComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  public buyers: SingleBuyer[] = [];
  public selectedBuyer!: SingleBuyer;
  public selectedBuyerConfig: GenericConfigFields = { selectAll: false };
  public buyerConfigData!: BuyerConfigData;
  public configFields: ConfigFields[] = [];
  public isDQField: any = [];
  public hiddenConfigsForProduction = HIDDEN_CONFIGS_FOR_PRODUCTION;
  public isProductionEnv = environment.production;
  public CONFIG_FEATURES_DOCUMENTATION_LINK = configFeaturesDocLink;
  public countriesList: EachCountry[] = [];
  public countries: EachCountry[] = [];
  public defaultCountry!: EachCountry | undefined;
  public permittedCountries: EachCountry[] = [];
  public blockedCountries: EachCountry[] = [];
  public blockedCountriesSectionIndex = blockedCountriesSectionIndex;
  public blockedCountriesFeatureIndex = blockedCountriesFeatureIndex;

  constructor(
    private _toastr: ToasterService,
    private _buyerService: BuyerService,
    private _buyerConfigService: BuyerConfigService,
    private _activityService: AcitivityService,
    private _modal: ModalService,
    private _commonService: CommonService,
    private _buyerSettingsService: BuyerSettingsService
  ) {}

  ngOnInit(): void {
    this.getBuyers();
  }

  getBuyers() {
    const getBuyers = this._buyerService.getBuyers().subscribe(
      (buyerResponse) => {
        this.buyers = buyerResponse.company;
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
    this._subscriptions.add(getBuyers);
  }

  selectBuyer(buyer: SingleBuyer) {
    this.selectedBuyer = buyer;
    this.getBuyerConfigSettings(buyer.id);
  }

  private _hideConfigFieldsForProduction() {
    if (!this.isProductionEnv) {
      return;
    }
    // Disable for production
    this.configFields = this.configFields.map((field) => {
      field.features = field.features.map((feature) => {
        feature.hide = !!this.hiddenConfigsForProduction.includes(
          feature.value
        );
        return feature;
      });
      return field;
    });
  }

  _setBuyerConfigFields(buyerConfigData: BuyerConfigData) {
    this.configFields = Constants.CONFIG_FIELDS;
    this._hideConfigFieldsForProduction();
    this.buyerConfigData = buyerConfigData;
    this.isDQField = this.configFields.map((configField) => {
      return configField.features.filter(
        (feature) => feature.parentValue === 'enablePureScoreAndRD'
      );
    });
    this.configFields.forEach((eachConfigField) =>
      eachConfigField.features.forEach((feature) => {
        this.setFieldsValues(feature);
      })
    );
  }

  _setCountryFields(
    buyerConfigData: BuyerConfigData,
    countries: EachCountry[]
  ) {
    const { defaultCountry, blockedCountries } = buyerConfigData;
    this.countriesList = countries;
    this.defaultCountry = countries.find(
      (country) => country.short_Code === defaultCountry
    );
    this.permittedCountries = countries.filter(
      (country) => !blockedCountries.includes(country.short_Code)
    );
    this.countries = countries.filter(
      (country) => country.short_Code !== defaultCountry
    );
    this.blockedCountries = countries.filter((country) =>
      blockedCountries.includes(country.short_Code)
    );
  }

  getBuyerConfigSettings(buyerId: number) {
    this.configFields = [];
    forkJoin({
      buyerConfigData:
        this._buyerConfigService.getBuyerConfigByBuyerId(buyerId),
      countries: this._commonService.getCountries(),
    }).subscribe(
      ({ buyerConfigData, countries }) => {
        this._setBuyerConfigFields(buyerConfigData);
        this._setCountryFields(buyerConfigData, countries);
      },
      (error) => {
        this._toastr.error(error.error.ps_api_response_message);
      }
    );
  }

  setFieldsValues(feature: any): void {
    const isSelected = this.buyerConfigData.features.includes(feature.value);
    this.setFieldSelected(feature, isSelected);
  }

  setFieldSelected(feature: any, selected: boolean): void {
    if (selected && this.isDQField.includes(feature)) {
      feature.disabled = false;
    }

    this.selectedBuyerConfig[feature.value] = selected;
  }

  async updateConfigSettings(
    config: string,
    hasAdditionalInfo: boolean = false
  ) {
    const configHandlers: Record<string, () => Promise<void>> = {
      enablePureScoreAndRD: () => this.handleParentAndChild(config),
      enableTargetMargin: this.handleEnableTargetMargin,
      enableTrafficChannels: this.handleEnableTrafficChannels,
      enableProjectLevelDataQuality: this.enableProjectLevelDataQuality,
      enableMandatoryBillingNumber: this.enableMandatoryBillingNumber,
      enableBillingNumberRules: this.enableBillingNumberRules,
      enableDynamicDash: this.enableDynamicDash,
      enableFilteringSortingBulkEdit: this.enableFilteringSortingBulkEdit,
      enableRealTimeNotification: () => this.handleRealTimeNotification(config),
      decipher: this.decipher,
      enableViewByPM: () => this.handleParentAndChild(config),
      enableAiDeliveryAssistance: () => this.handleAiDeliveryAssistance(config),
      enableMarginMax: () => this.handleAiDeliveryAssistance(config),
      overridCmp: () => this.handleAiDeliveryAssistance(config),
      enablePurePriceRates: () => this.handleAiDeliveryAssistance(config),
      qbp: () => this.handleAiDeliveryAssistance(config),
    };

    const handler = configHandlers[config];
    if (handler) {
      await handler();
    }

    if (hasAdditionalInfo && !this.selectedBuyerConfig[config]) {
      this.getBuyerConfigAddInfo(config);
    }

    await this.updateSettings(
      this.updateObject(config, !this.selectedBuyerConfig[config]),
      true
    );
  }

  updateObject(config: string, enable: boolean) {
    const updateObject: UpdateBuyerConfigData = {
      id: this.selectedBuyer.id,
      config,
      enable,
    };
    return updateObject;
  }

  async handleRealTimeNotification(config: string) {
    // true is the old value, mean that the user uncheck the slide
    if (this.selectedBuyerConfig[config]) {
      await this._activityService
        .unsubscribe(this.selectedBuyer.id)
        .pipe(
          catchError((_) => {
            this._toastr.error(
              'Error while unsubscribing from real time notification'
            );
            return of({});
          })
        )
        .toPromise();
    }
  }

  handleParentAndChild = async (parent: string) => {
    const sections = this.configFields.map((section) => section.features);
    const features = sections.flat(1);
    const promises = features
      .filter((eachConfigField) => {
        return eachConfigField.parentValue === parent;
      })
      .map(async (eachConfigField) => {
        const configEnabled = !this.selectedBuyerConfig[parent];
        eachConfigField.disabled = false;
        if (parent === 'enablePureScoreAndRD') {
          this.selectedBuyerConfig[eachConfigField.value] = !configEnabled;
        }

        if (
          eachConfigField.parentValue === 'enableViewByPM' &&
          configEnabled === false
        ) {
          await this.updateSettings(
            this.updateObject(eachConfigField.value, false),
            false
          );
        }
      });

    await Promise.all(promises);
  };

  enableProjectLevelDataQuality = async () => {
    const configEnabled =
      !this.selectedBuyerConfig.enableProjectLevelDataQuality;

    const promises = this.configFields
      .filter((eachConfigField) => {
        eachConfigField.features.forEach((feature) => {
          return (
            feature.parentValue === 'enablePureScoreAndRD' &&
            feature.value !== 'enableProjectLevelDataQuality'
          );
        });
      })
      .map(async (eachConfigField) => {
        eachConfigField.features.map((feature) => {
          if (!configEnabled && this.selectedBuyerConfig[feature.value]) {
            return this.updateConfigSettings(feature.value, true);
          }
          return (feature.disabled = !configEnabled);
        });
      });

    await Promise.all(promises);
  };

  handleEnableTargetMargin = async () => {
    if (!!this.selectedBuyerConfig.enableTargetMargin) {
      this.selectedBuyerConfig.enableTargetMarginLaunchAll = false;
      await this.updateSettings(
        this.updateObject('enableTargetMarginLaunchAll', false)
      );
    }
  };

  handleEnableTrafficChannels = async () => {
    const configEnabled = !this.selectedBuyerConfig.enableTrafficChannels;
    if (configEnabled) {
      // PD-8270 if traffic channel config is set true then need to set true setting of survey external id
      this.selectedBuyerConfig.enableSurveyExternalId = true;
      await this.updateSettings(
        this.updateObject('enableSurveyExternalId', true)
      );
    }
  };

  enableMandatoryBillingNumber = async () => {
    const { enableMandatoryBillingNumber, enableBillingNumberRules } =
      this.selectedBuyerConfig;

    if (!enableMandatoryBillingNumber && enableBillingNumberRules) {
      await this.updateConfigSettings('enableBillingNumberRules', false);
    }
  };

  enableBillingNumberRules = async () => {
    const { enableMandatoryBillingNumber, enableBillingNumberRules } =
      this.selectedBuyerConfig;

    if (!enableBillingNumberRules && enableMandatoryBillingNumber) {
      await this.updateConfigSettings('enableMandatoryBillingNumber', false);
    }
  };

  decipher = async () => {
    if (!this.selectedBuyerConfig.decipher) {
      this.selectedBuyerConfig.enableSurveyExternalId = false;
      await this.updateSettings(
        this.updateObject('enableSurveyExternalId', true)
      );
    } else {
      this._toastr.warning(notifyMessage.warningMessage.CHECK_EXTERNAL_ID);
    }
  };

  private _checkBlockedInvoiceChangedOverTs() {
    const invoiceChangeOverTS = this.selectedBuyer.invoiceChangeOverTS;
    if (typeof invoiceChangeOverTS !== 'number') {
      return 'blocked';
    }
    const today = new Date();
    const thirtyDaysInMs = Constants.THIRTYDAYSMS;
    const lessThan30Days = today.getTime() - thirtyDaysInMs;
    if (invoiceChangeOverTS > lessThan30Days) {
      return 'blocked';
    }
    return false;
  }

  private _verifyBlockedDisableSurveyAutoclose(config: string) {
    if (config === 'disableSurveyAutoclose') {
      const tempGenerateInvoice = this.selectedBuyer.tempGenerateInvoice;
      const hasTempGeneratedInvoiceChangedRecently =
        this._checkBlockedInvoiceChangedOverTs();
      if (
        !tempGenerateInvoice ||
        tempGenerateInvoice !== 'MONTHLY' ||
        hasTempGeneratedInvoiceChangedRecently === 'blocked'
      ) {
        this._toastr.error(
          `Disable Survey Auto Close is only available when Buyer Invoice type is Monthly for more than 30 days.`
        );
        this.selectedBuyerConfig[config] = !this.selectedBuyerConfig[config];
        setTimeout(() => {
          this.selectedBuyerConfig[config] = false;
        }, 100);
        return true;
      }
    }
    return;
  }

  private _automatedYeildManagementEnabled(
    config: string,
    configEnabled: boolean
  ) {
    return (
      config === 'enableAutomatedYieldManagement' &&
      configEnabled &&
      !this.selectedBuyerConfig.enableSimulatedYieldManagement
    );
  }

  async updateSettings(
    data: UpdateBuyerConfigData,
    showSuccess: boolean = false
  ) {
    if (this._verifyBlockedDisableSurveyAutoclose(data.config)) {
      return;
    }

    try {
      await this._buyerConfigService
        .updateBuyerConfig(this.buyerConfigData.configSettingsId, data)
        .toPromise();

      if (showSuccess) {
        this._toastr.success('Buyer information has been added !');
      }

      this.selectedBuyerConfig[data.config] =
        !this.selectedBuyerConfig[data.config];

      if (data.config === 'enableAutoFieldManagement' && data.enable) {
        this.selectedBuyerConfig.enableTrafficChannels = true;
      }
      if (data.config === 'includeToo' && !data.enable) {
        this.selectedBuyerConfig.enableFilteringByDate = false;
      }
    } catch (err: any) {
      let message =
        'Error:  Required information is missing. Please update to continue';
      if (
        ['enableMarginMax', 'enableAiDeliveryAssistance'].includes(
          data.config
        ) &&
        err.error.apistatus === 'warning'
      ) {
        this.selectedBuyerConfig[data.config] =
          !this.selectedBuyerConfig[data.config];
        message = err.error.msg;
      }
      if (err.error.apistatus === 'warning') {
        this._toastr.warning(message);
      } else {
        this._toastr.error(message);
      }
    }
  }

  public getBuyerConfigAddInfo(config: string) {
    const data: ConfigSettingsModalData = {
      buyerId: this.selectedBuyer.id,
      buyerName: this.selectedBuyer.name,
    };
    if (config === 'enableNightcrawler') {
      this._modal.open(EnableNightcrawlerModalComponent, {
        data,
      });
    } else if (config === 'samplify') {
      this._modal.open(RnCredentialsModalComponent, {
        data,
      });
    } else if (config === 'decipher') {
      this._modal.open(EnableDecipherModalComponent, {
        data,
      });
    } else if (config === 'enableRealTimeNotification') {
      this._modal.open(EnableRealTimeNotificationModalComponent, {
        data,
      });
    }
  }

  isParent(key: string) {
    if (Constants.PARENT_FIELDS.includes(key)) {
      return true;
    }
    return false;
  }

  enableDynamicDash = async () => {
    const { enableDynamicDash, enableFilteringSortingBulkEdit } =
      this.selectedBuyerConfig;
    if (enableDynamicDash && enableFilteringSortingBulkEdit) {
      await this.updateConfigSettings('enableFilteringSortingBulkEdit', false);
    }
  };

  enableFilteringSortingBulkEdit = async () => {
    const { enableDynamicDash, enableFilteringSortingBulkEdit } =
      this.selectedBuyerConfig;
    if (!enableFilteringSortingBulkEdit && !enableDynamicDash) {
      await this.updateConfigSettings('enableDynamicDash', true);
    }
  };

  public selectDefaultCountry(defaultCountry: EachCountry) {
    const payload = {
      ...this._mapBuyerSettingPayload(),
      default_country: defaultCountry.short_Code,
    } as unknown as UpdateObj;

    this._buyerSettingsService
      .updateBuyerSettings(payload, this.selectedBuyer.id)
      .subscribe(
        (res) => {
          this.defaultCountry = defaultCountry;
          this.countries = this.countriesList.filter(
            (country) => country.short_Code !== defaultCountry.short_Code
          );
          this._toastr.success('Default Country Updated Successfully.');
        },
        (err) => {
          const errorMessage = err.error.msg;
          this._toastr.error(errorMessage);
        }
      );
  }

  public setBlockedCountries(countries: EachCountry[]) {
    const payload = {
      ...this._mapBuyerSettingPayload(),
      blocked_countries: countries.map((country) => country.short_Code),
    } as unknown as UpdateObj;
    this._buyerSettingsService
      .updateBuyerSettings(payload, this.selectedBuyer.id)
      .subscribe(
        (res) => {
          this.blockedCountries = countries;
          this.permittedCountries = this.countriesList.filter(
            (country) =>
              !payload.blocked_countries?.includes(country.short_Code)
          );
          this._toastr.success('Country Blocked Successfully.');
        },
        (err) => {
          const errorMessage = err.error.msg;
          this._toastr.error(errorMessage);
        }
      );
  }

  private _mapBuyerSettingPayload() {
    const { id: cmp, name: cmpName } = this.selectedBuyer;
    return {
      cmp,
      cmpName,
    };
  }

  async handleAiDeliveryAssistance(config: string) {
    const configFields = [
      'enableMarginMax',
      'overridCmp',
      'enablePurePriceRates',
      'qbp',
    ];
    // If 'enableAiDeliveryAssistance' is not enabled, activate the mandatory features
    if (
      !this.selectedBuyerConfig[config] &&
      config === 'enableAiDeliveryAssistance'
    ) {
      let isInfoMessage = false;
      for (const eachConfigField of configFields) {
        if (!this.selectedBuyerConfig[eachConfigField]) {
          isInfoMessage = true;
        }
        await this.updateSettings(this.updateObject(eachConfigField, true));
        this.selectedBuyerConfig[eachConfigField] = true;
      }
      if (isInfoMessage) {
        this._toastr.info(
          notifyMessage.infoMessage.AIDA.REQUIRE_FEATURE_ACTIVATE
        );
      }
    }
    // If the config is one of the configField and is desabled, deactivate 'enableAiDeliveryAssistance'
    if (
      configFields.includes(config) &&
      this.selectedBuyerConfig[config] &&
      this.selectedBuyerConfig['enableAiDeliveryAssistance']
    ) {
      this._toastr.info(
        notifyMessage.infoMessage.AIDA.REQUIRE_FEATURE_DEACTIVATE
      );
      await this.updateSettings(
        this.updateObject('enableAiDeliveryAssistance', false),
        true
      );
      this.selectedBuyerConfig['enableAiDeliveryAssistance'] = false;
    }
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}

export interface SingleBuyer {
  name: string;
  id: number;
  tempGenerateInvoice: string;
  invoiceChangeOverTS: number;
  blocked: boolean;
}

interface BuyerConfigData {
  configSettingsId: string;
  blockedCountries: string[];
  defaultCountry: string;
  features: string[];
}
interface GenericConfigFields {
  [key: string]: boolean;
}
interface ConfigFields {
  section: string;
  features: {
    name: string;
    value: string;
    desc: string;
    parent?: boolean;
    parentValue?: string;
    hasAdditionalInfo: boolean;
    disabled: boolean;
    hide?: boolean;
  }[];
}
interface UpdateBuyerConfigData {
  id: number;
  config: string;
  enable: boolean;
}
export interface ConfigSettingsModalData {
  buyerId: number;
  buyerName: string;
}
