<div class="hasing-tabs">
  <div class="">
    <h4>Supplier Setting</h4>
  </div>
  <ps-ui-tab-group (activeIndexChange)="checkActiveTab($event)">
    <ps-ui-tab *ngFor="let tab of tabs" [label]="tab.title"></ps-ui-tab>
  </ps-ui-tab-group>

  <form class="hashing-form" *ngIf="form" [formGroup]="form">
    <ps-exclusion-settings
      [hidden]="activeTab.title !== 'EXCLUSION SETTINGS'"
      [form]="form"
    >
    </ps-exclusion-settings>

    <ps-hashing
      [hidden]="activeTab.title !== 'HASHING'"
      [form]="form"
    ></ps-hashing>

    <ps-redirect-url
      [hidden]="activeTab.title !== 'REDIRECT URL'"
      [form]="form"
    ></ps-redirect-url>

    <ps-variable-mapping
      [hidden]="activeTab.title !== 'VARIABLE MAPPING'"
      [form]="form"
    ></ps-variable-mapping>

    <ps-notification-status
      [hidden]="activeTab.title !== 'NOTIFICATION STATUS'"
      [form]="form"
    >
    </ps-notification-status>

    <ps-s2scallbacks
      [hidden]="activeTab.title !== 'S2S CALLBACKS'"
      [form]="form"
    >
    </ps-s2scallbacks>

    <ps-micro-surveys
      [hidden]="activeTab.title !== 'MICRO SURVEYS'"
      [form]="form"
    >
    </ps-micro-surveys>
  </form>

  <div class="hasing-buttons text-center mt-6">
    <button
      class=""
      type="button"
      psUiButton
      size="large"
      color="primary"
      [disabled]="form.invalid"
      (click)="saveSettingData()"
    >
      Save
    </button>
    <button
      class=""
      id="dashboard"
      type="button"
      psUiButton
      size="large"
      color="primary"
      [routerLink]="['/dashboard/']"
    >
      Back to Dashboard
    </button>
  </div>
</div>
