<div class="form-inline" [formGroup]="microSurveyForm">
  <div class="row">
    <div class="col-md-1">
      <label class="pull-left" style="margin-right: 15px">Suppliers</label>
    </div>
    <div class="col-sm-6 col-md-4 form-group mb-35">
      <ps-ui-select-dropdown
        [dropdownItems]="suppliers"
        [field]="'name'"
        [enableSearch]="true"
        labelNone="Select Supplier"
        [label]="selectedSupplier?.name"
        (selectedItem)="selectSupplier($event)"
      >
      </ps-ui-select-dropdown>
    </div>
  </div>

  <div class="well" *ngIf="selectedSupplier && selectedSupplierConfig">
    <div class="col-xs-12 flex-align buyer-header">
      <h4 class="text-uppercase text-center mar35">
        {{ selectedSupplier.name }}
      </h4>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-4 col-md-offset-4 row-width">
        <div class="flex-row">
          <div class="acordion-container">
            <ps-ui-accordion
              class="acordion"
              *ngFor="let section of supplierFields; index as i"
            >
              <ps-ui-accordion-section [title]="section.section">
                <div class="col-xs-3" *ngFor="let config of section.features">
                  <div
                    class="softlaunch-toggle-btn config-tab"
                    *ngIf="config.value === 'supplierType'"
                  >
                    <label class="config-name-label">{{ config.name }}</label>
                    <label class="pull-right">
                      <ps-ui-capsule-radio-button
                        class="capsule-button-row"
                        [value]="selectedSupplierConfig[config.value]"
                        [onLabel]="'Private'"
                        [offLabel]="'Public'"
                        (valueChange)="
                          updateSupplierSettings(
                            $event,
                            config.value,
                            section.section
                          )
                        "
                      >
                      </ps-ui-capsule-radio-button>
                    </label>
                  </div>
                  <div
                    class="softlaunch-toggle-btn config-tab"
                    *ngIf="config.value !== 'supplierType'"
                  >
                    <label class="config-name-label">{{ config.name }}</label>
                    <label
                      class="status pull-right"
                      *ngIf="!showInput(config.value)"
                    >
                      <ps-ui-slider
                        (selectedChange)="
                          updateSupplierSettings(
                            $event,
                            config.value,
                            section.section
                          )
                        "
                        [selected]="
                          section.section === 'MICROSURVEY_SETTINGS'
                            ? microSurveyForm.value[config.value]
                            : selectedSupplierConfig[config.value]
                        "
                      ></ps-ui-slider>
                    </label>
                    <label class="pull-right" *ngIf="showInput(config.value)">
                      <input
                        type="{{
                          config.value === 'currencyName' ? 'text' : 'number'
                        }}"
                        [formControlName]="config.value"
                      />
                    </label>
                  </div>
                </div>
                <div
                  class="pull-right pb-1"
                  *ngIf="section.section === 'MICROSURVEY_SETTINGS'"
                >
                  <button
                    type="submit"
                    psUiButton
                    color="primary"
                    (click)="saveMicroSurveySettings()"
                  >
                    Save
                  </button>
                </div>
              </ps-ui-accordion-section>
            </ps-ui-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
