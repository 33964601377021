import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { SupplierSettingsResponse } from '../supplier-settings/supplier-settings.interface';

@Injectable({
  providedIn: 'root',
})
export class SupplierSettingService {
  private readonly _baseUrl = environment.pureSpectrum.url;

  constructor(private _http: HttpClient) {}

  updateSupplierSetting(cmp: number, data: {}) {
    return this._http.put<{ apiStatus: string; msg: string }>(
      `${this._baseUrl}/supplierSettings/${cmp}`,
      data
    );
  }

  getSupplierSettings(supplierId: number) {
    return this._http.get<SupplierSettingsResponse>(
      `${this._baseUrl}/supplierSettings/${supplierId}`
    );
  }
}
