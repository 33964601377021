import { Injectable } from '@angular/core';
import { notifyMessage } from '../constants/notify-message';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  OperatorSettingsResponse,
  PSIDUploadResponse,
} from './operator-settings.interface';

@Injectable({
  providedIn: 'root',
})
export class OperatorSettingsService {
  private readonly _baseUrl = environment.pureSpectrum.url;

  constructor(private _http: HttpClient, private _toastr: ToasterService) {}

  public validateFileExtention(file: File, validExtentions: Array<string>) {
    if (!file) {
      this._toastr.error(notifyMessage.errorMessage.FILE_UPLOAD_MISSING);
      return false;
    }
    const fileExt = file.name.substring(file.name.lastIndexOf('.'));
    if (validExtentions.indexOf(fileExt) < 0) {
      this._toastr.error(
        notifyMessage.errorMessage.FILE_UPLOAD_ERROR_GENERIC.replace(
          '<FILE_EXTENSION>',
          validExtentions.join('/')
        )
      );
      return false;
    }
    return true;
  }

  public getOperatorSettings() {
    return this._http.get<OperatorSettingsResponse>(
      `${this._baseUrl}/operatorSettings/`
    );
  }

  public updateOperatorSettings(id: number, data: {}) {
    return this._http.put<OperatorSettingsResponse>(
      `${this._baseUrl}/operatorSettings/${id}`,
      data
    );
  }

  public uploadPSIDs(data: FormData) {
    return this._http.post<PSIDUploadResponse>(
      `${this._baseUrl}/operator-settings/purescore/update`,
      data
    );
  }
}
