<form class="form-horizontal-select-dropdown" [formGroup]="billingForm">
  <div class="container custom-container">
    <div class="row first-section-tabs">
      <div class="col-md-5 characters-input">
        <p>CHARACTERS</p>
        <div class="min-max-box" formGroupName="length">
          <div class="min-field">
            <label>Min</label>
            <input
              type="number"
              class=""
              formControlName="min"
              (focusout)="validateBillingNumberChar($event, true)"
              psInputRestriction="[0-9]"
              [readOnly]="this.billingRulesArray.length > 2"
              [ngClass]="{
                'disabled bg-secondary border-0 rounded-2':
                  this.billingRulesArray.length > 2
              }"
            />
          </div>
          <span style="line-height: 6">to</span>
          <div class="max-field">
            <label>Max</label>
            <input
              type="number"
              class=""
              formControlName="max"
              (focusout)="validateBillingNumberChar($event, false, true)"
              psInputRestriction="[0-9]"
            />
          </div>
          <p>(Max 42 Chars)</p>
        </div>
        <p class="mt-2 my-auto">CHARACTERS RULE REMAINED:</p>
        <p>{{ characterRemainLabel }}</p>
      </div>
      <div class="col-md-7 characters-input-max">
        <p>
          <small
            >IS THE BILLING NUMBER REQUIRED BEFORE THE USER CAN LAUNCH THEIR
            PROJECT?</small
          >
        </p>
        <div class="d-flex flex-row align-items-center">
          <div class="">
            <ps-ui-slider
              id="mandatory"
              (selectedChange)="setBillingNumberRequired($event)"
              [selected]="billingForm.value.mandatory"
            ></ps-ui-slider>
          </div>
          <div class="mx-2 mt-1">
            <p>IS REQUIRED</p>
          </div>
          <div class="mb-2">
            <div class="tooltip-visible">
              <div class="fa fa-info-circle tootip-setting-info setting-info">
                <p>
                  If the billing number is not mandatory, the user can launch
                  the survey without a billing number. If a survey without a
                  billing number is invoiced, a random billing number following
                  this ruleset will be generated automatically upon invoicing.
                </p>
              </div>
            </div>
          </div>
        </div>
        <p class="text-uppercase">
          <small
            >Should we auto-populate a randomized billing number in the Draft
            Launch Survey page?</small
          >
        </p>
        <div class="d-flex flex-row align-items-center">
          <div>
            <ps-ui-slider
              id="autoGenerate"
              (selectedChange)="setAutoGenerateBillingNumberFlag($event)"
              [selected]="billingForm.value.autoGenerate"
            ></ps-ui-slider>
          </div>
          <div class="mx-2 mt-1">
            <p>AUTO-POPULATE</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="billing-rule-container">
    <div class="row justify-content-center">
      <div class="table-responsive overflow-hidden">
        <table class="table billing-rule-table">
          <thead class="text-light">
            <th>Character Range (Min to Max)</th>
            <th>Rule</th>
            <th>Type</th>
            <th>Is mandatory</th>
            <th>Action</th>
          </thead>
          <tbody class="add-input-fields" formArrayName="rules">
            <ng-container
              *ngFor="let control of billingRules.controls; let i = index"
              [formGroupName]="i"
            >
              <!-- Only render the row if isDisableFirstRow is false or it's not the first row -->
              <ng-container *ngIf="!isDisableFirstRow() || i > 0">
                <tr
                  [ngClass]="{
                    disabled:
                      isMaximumCharacterLimitReached && checkIndex(i, '===', 0)
                  }"
                >
                  <td>
                    <ng-container formGroupName="range">
                      <input
                        type="number"
                        class="form-control border-0 border-bottom text-center rounded-0 mt-3"
                        formControlName="start"
                        [minlength]="1"
                        (focusout)="validateBillingRuleMinMaxInput(i, $event)"
                        [readOnly]="true"
                        psInputRestriction="[0-9]"
                        [ngClass]="{
                          disabled:
                            checkIndex(i, '>', 0) ||
                            checkIndex(i, '===', billingRulesArray.length - 1)
                        }"
                      />
                      <span class="px-2">to</span>
                      <input
                        type="number"
                        class="form-control border-0 border-bottom text-center rounded-0 mt-3"
                        formControlName="end"
                        (focusout)="validateBillingRuleMinMaxInput(i, $event)"
                        psInputRestriction="[0-9]"
                        [ngClass]="{ disabled: i > 0 && !isRowEditable }"
                      />
                    </ng-container>
                  </td>
                  <td [ngClass]="{ disabled: i > 0 && !isRowEditable }">
                    <div class="rules-dropdown d-flex">
                      <div [ngClass]="isEntryTypeFixed(i) ? 'col-5' : 'col'">
                        <ps-ui-select-dropdown
                          id="with-search"
                          formControlName="type"
                          [dropdownItems]="entryTypeDropdownValues"
                          [field]="'label'"
                          (selectedItem)="updateEntryTypesValue(i, $event)"
                          [enableSearch]="false"
                        ></ps-ui-select-dropdown>
                      </div>

                      <div class="ms-2 col-5" *ngIf="isEntryTypeFixed(i)">
                        <input
                          type="text"
                          class="form-control text-center rounded-0 full-height"
                          (focusout)="
                            validateBillingRuleMinMaxInput(i, $event, true)
                          "
                          formControlName="definition"
                        />
                      </div>
                      <div class="col-2" *ngIf="isEntryTypeFixed(i)">
                        <div class="tooltip-visible">
                          <div
                            class="fa fa-info-circle tootip-setting-info setting-info"
                          >
                            <p>
                              You can use any character and symbols based on
                              these ranges:
                              {{
                                '“a-z”, “A-Z”, "0-9” and “{} [] () /\ ~ - , ; : . < >“'
                              }}. Make sure to follow the defined character
                              range.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td [ngClass]="{ disabled: i > 0 && !isRowEditable }">
                    <div
                      class="form-check text-start"
                      [ngClass]="{ disabled: isEntryTypeFixed(i) }"
                    >
                      <label class="form-check-label">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [checked]="isCheckboxSelected(CharacterType.Alpha, i)"
                          (click)="updateChar(CharacterType.Alpha, i)"
                        />
                        <span>Alpha</span>
                      </label>
                      <br />
                      <label class="form-check-label">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [checked]="
                            isCheckboxSelected(CharacterType.Numeric, i)
                          "
                          (click)="updateChar(CharacterType.Numeric, i)"
                        />
                        <span>Numeric</span>
                      </label>
                      <br />
                      <label class="form-check-label">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [checked]="
                            isCheckboxSelected(CharacterType.Special, i)
                          "
                          (click)="updateChar(CharacterType.Special, i)"
                        />
                        <span>Special Characters</span>
                      </label>
                    </div>
                  </td>
                  <td
                    [ngClass]="{ disabled: i === billingRulesArray.length - 1 }"
                  >
                    <div class="flex align-items-center justify-content-center">
                      <span>No</span>
                      <ps-ui-slider
                        class="mx-2"
                        [selected]="requiredStatus(i)"
                        (selectedChange)="
                          updateRequiredStatus(i, $event, false)
                        "
                      ></ps-ui-slider>
                      <span>Yes</span>
                    </div>
                  </td>
                  <td>
                    <a
                      class="link-primary"
                      *ngIf="
                        (i === 0 && !isRowEditable) ||
                        (isRowEditable && i !== 0)
                      "
                      (click)="addNewRuleRow(i)"
                      [attr.disabled]="isMaximumCharacterLimitReached"
                    >
                      <i class="fa fa-plus"></i>
                      {{
                        isRowEditable && checkIndex(i, '===', 1)
                          ? 'Save Changes'
                          : 'Click to Add'
                      }}
                    </a>
                    <a
                      class="link-primary"
                      *ngIf="
                        checkIndex(i, '===', 1) &&
                        i !== billingRulesArray.length - 1
                      "
                      (click)="deleteRuleRow(i)"
                    >
                      <i class="fa fa-times"></i>
                    </a>
                    <a
                      class="link-primary"
                      *ngIf="isEditButtonVisible(i) && !isRowEditable"
                      (click)="editRow(i)"
                      ><i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </a>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</form>
<div class="container custom-container">
  <div class="first-section-tabs">
    <div *ngIf="!example">
      <p>Save your ruleset to see the following previews:</p>
      <p>
        EXAMPLE:
        {{ billingNumberRules ? billingNumberRules.example : 'XXXX-XXXXX' }}
      </p>
      <p>Billing Number Rules: N/A</p>
    </div>

    <p *ngIf="example">
      <span>
        PO Structure:
        <br />
        <br />
        Example: {{ example }}
        <br />
        <br />
        <span *ngFor="let rule of structure">
          {{ rule.entry }}
          <ul>
            <li>{{ rule.characters.position }}</li>
            <li>{{ rule.characters.requirement }}</li>
            <li>{{ rule.characters.type }}</li>
          </ul>
        </span>
      </span>
      <br />
      <span>
        GLOSSARY
        <ul>
          <li>Alpha ( e.g. “a-z” and “A-Z”)</li>
          <li>Numeric (e.g. "0-9”)</li>
          <li>Special Characters (e.g. {{ specialChars }})</li>
        </ul>
      </span>
    </p>
  </div>
</div>
