'use strict';

import { SurveyData } from '@purespectrum1/ui/survey-top-view/shared/survey.interface';
import { SurveyStats } from './bulk-edit/types';
import { HealthCheckCount } from '../shared/services/buyer-api/survey.interface';

export const Constants = Object.freeze({
  SURVEY_STATUS: {
    11: 'Draft',
    22: 'Live',
    33: 'Paused',
    44: 'Closed',
    55: 'Invoice',
  },
  MAX_CPI_THRESHOLD: 10,
  SURVEY_TABS: [
    {
      title: 'ALL' as const,
      status: 'all',
      count: 0,
      roleAccess: ['buyer', 'operator', 'supplier'],
      active: false,
    },
    {
      title: 'LIVE' as const,
      status: '22',
      count: 0,
      roleAccess: ['buyer', 'operator', 'supplier'],
      active: true,
    },
    {
      title: 'PAUSED' as const,
      status: '33',
      count: 0,
      roleAccess: ['buyer', 'operator', 'supplier'],
      active: false,
    },
    {
      title: 'DRAFTS' as const,
      status: '11',
      count: 0,
      roleAccess: ['buyer'],
      active: false,
    },
    {
      title: 'CLOSED' as const,
      status: '44',
      count: 0,
      roleAccess: ['buyer', 'operator', 'supplier'],
      active: false,
    },
    {
      title: 'INVOICED' as const,
      status: '55',
      count: 0,
      roleAccess: ['buyer', 'operator'],
      active: false,
    },
  ],
  SURVEY_STATUS_SHOW_COUNT: ['22', '33'],
  SURVEY_STATUS_CHANGE_FOR_CHURNZERO: [33, 44],
  SUPPLIER_V2_SURVEY_MAP: {
    survey_status: 'status',
    survey_id: 'surveyId',
    survey_name: 'surveyTitle',
    achieved: 'fielded',
    needed: 'goal',
    cpi: 'averageCpi',
    incidence_current: 'currentIR',
    country_code: 'countryCode',
    currency_symbol: 'currencySymbol',
    supplier_completes: {},
    encription_id: 'encriptionId',
    template: 'template',
  },
  BUYER_V2_SURVEY_MAP: {
    ps_survey_status: 'status',
    ps_survey_id: 'surveyId',
    survey_title: 'surveyTitle',
    company_name: 'buyerAccount',
    fielded: 'fielded',
    completes_required: 'goal',
    bip: 'inProgress',
    average_cpi: 'averageCpi',
    acpi: 'aCpi',
    epc: 'epc',
    is_enable_margin_override: 'is_enable_margin_override',
    is_enable_margin_max: 'is_enable_margin_max',
    manual_scpi_override_enabled: 'manual_scpi_override_enabled',
    current_incidence: 'currentIR',
    country_code: 'countryCode',
    currency_symbol: 'currencySymbol',
    encription_id: 'encriptionId',
    survey_category_code: 'samplesType',
    expected_loi: 'expected_loi',
    margin_bank: 'margin_bank',
    actual_margin: 'actual_margin',
    current_cost: 'current_cost',
    mc_count: 'mc_count',
    tc_count: 'tc_count',
    reconciled: 'reconciled',
    template: 'template',
    crm_id: 'crm_id',
    operator_pm: 'operator_pm',
    buyer_pm: 'buyer_pm',
    survey_bar_hour: 'survey_bar_hour',
    survey_line_day: 'survey_line_day',
  },
  MANAGE_SURVEY_TABS: [
    {
      key: 'QUOTAS',
      title: 'QUOTAS',
      active: true,
    },
    {
      key: 'AUDIENCE',
      title: 'AUDIENCE',
      active: false,
    },
    {
      key: 'SUPPLIERS',
      title: 'SUPPLIERS',
      active: false,
    },
    {
      key: 'REPORTS',
      title: 'REPORTS',
      active: false,
    },
    {
      key: 'LINK_OTHER_DETAILS',
      title: 'LINK/OTHER DETAILS',
      active: false,
    },
    {
      key: 'RECONCILE',
      title: 'RECONCILE',
      active: false,
    },
    {
      key: 'ACTIVITY_LOGS',
      title: 'ACTIVITY LOGS',
      active: false,
    },
    {
      key: 'TRAFFIC_CHANNELS',
      title: 'TRAFFIC CHANNELS',
      active: false,
    },
    {
      key: 'MULTI_COUNTRY',
      title: 'MULTI-COUNTRY',
      active: false,
      className: 'multi-country',
    },
  ],
  DAYS: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  QUOTAPAGELIMIT: 100,
  SURVEY_SUPPLIER_TAB_KEY: [
    'QUOTAS',
    'AUDIENCE',
    'LINK_OTHER_DETAILS',
    'REPORTS',
  ],
  API_CALL_DEFAULT_QUOTA: 1,
  MODAL_MESSAGE: {
    UPDATE_SURVEY_STATUS: 'statusSuccessfullyUpdated',
    DELETE_SURVEY: 'surveySuccessfullyDeleted',
    SAVE_DASHBOARD_PREFERENCES: 'Dashboard preferences saved with success!',
  },
  MODEL_STATUS: {
    PAUSED: 'Paused',
    CLOSED: 'Closed',
  },
  SHOW_NEXT_QUOTAS_LABELS: {
    LIMITED: 'showLimitedQuotas',
    ALL: 'showAllQuotas',
  },
  STATUS_CHANGE_MESSAGE: 'statusSuccessfullyUpdated',
  SUPPLIER_INTENT_ICON: {
    YELLOW_EXCLAMATION: 'YELLOW_EXCLAMATION',
    GREEN_CHECK: 'GREEN_CHECK',
    RED_CROSS: 'RED_CROSS',
    YELLOW_CROSS: 'YELLOW_CROSS',
    BLACK_QUESTION: 'BLACK_QUESTION',
  },
  CURRENT_TARGET_TYPE: {
    NUMBER: 'Number',
    PERCENTAGE: 'Percentage',
  },
  SURVEY_SAMPLE_NAME: 'Augment Survey',
  RECONTACT_SURVEY_SAMPLE_TYPE: 234,
  SURVEY_LISTING_TABLE_HEADERS: {
    STATUS: 'status',
    SURVEY_ID: 'surveyId',
    SURVEY_TITLE: 'surveyTitle',
    FIELDED: 'fielded',
    IN_PROGRESS: 'inProgress',
    AVERAGE_CPI: 'averageCpi',
    CURRENT_IR: 'currentIR',
    COUNTRY_CODE: 'countryCode',
    BUYER_ACCOUNT: 'buyerAccount',
    LOI: 'expected_loi',
    EPC: 'epc',
    PROJECT_COST: 'current_cost',
    BANK: 'margin_bank',
    MARGIN: 'actual_margin',
    TRAFFIC_CHANNELS: 'trafficChannels',
    MULTI_COUNTRY: 'multiCountry',
    LAUNCH_DATE: 'survey_launch_date',
    RECONCILED: 'reconciled',
    HEALTH_CHECK: 'healthCheck',
    BILLING_NUMBER: 'billing_id',
    TEMPLATE: 'template',
    CRM_ID: 'crm_id',
    OPERATOR_PROJECT_MANAGER: 'operator_pm',
    BUYER_PROJECT_MANAGER: 'buyer_pm',
    PACING: 'pacing',
  },
  SUPPLIER_SURVEY_LISTING_TABLE_HEADERS: [
    {
      id: 1,
      order: 1,
      desktop: true,
      mobile: true,
      selected: true,
      column: 'Status',
      key: 'status',
    },
    {
      id: 2,
      order: 2,
      desktop: true,
      mobile: true,
      selected: true,
      column: 'Survey #',
      key: 'surveyId',
    },
    {
      id: 3,
      order: 3,
      desktop: true,
      mobile: true,
      selected: true,
      column: 'Survey Title',
      key: 'surveyTitle',
    },
    {
      id: 4,
      order: 4,
      desktop: true,
      mobile: true,
      selected: true,
      column: 'Fielded vs Goal',
      key: 'fielded',
    },
    {
      id: 8,
      order: 5,
      desktop: true,
      mobile: true,
      selected: true,
      column: 'Launch Date',
      key: 'survey_launch_date',
    },
    {
      id: 5,
      order: 6,
      desktop: true,
      mobile: true,
      selected: true,
      column: 'Avg. CPI',
      key: 'averageCpi',
    },
    {
      id: 6,
      order: 7,
      desktop: true,
      mobile: true,
      selected: true,
      column: 'Current IR',
      key: 'currentIR',
    },
    {
      id: 7,
      order: 8,
      desktop: true,
      mobile: true,
      selected: true,
      column: 'Country',
      key: 'countryCode',
    },
  ],
  TC_SURVEY_TABLE_HEADERS: [
    {
      label: 'Survey#',
      orderKey: 'ps_survey_id',
    },
    {
      label: 'Survey Title',
      orderKey: 'survey_title',
    },
    {
      label: 'Survey Type',
      orderKey: 'survey_type',
    },
    {
      label: 'Launched',
      orderKey: 'creation_date',
    },
    {
      label: 'Fielded Vs Goal',
      orderKey: 'fielded',
    },
    {
      label: 'Avg. CPI',
      orderKey: 'avg_cpi',
    },
    {
      label: 'Current CPI',
      orderKey: 'cpi_current',
    },
    {
      label: 'EPC',
      orderKey: 'epc',
    },
    {
      label: 'Current IR',
      orderKey: 'current_ir',
    },
    {
      label: 'Total Starts',
      orderKey: 'total_starts',
    },
    {
      label: 'Starts 24hrs',
      orderKey: 'last_24_starts',
    },
    {
      label: 'In Progress',
      orderKey: 'in_progress',
    },
  ],
  MC_SURVEY_TABLE_HEADERS: [
    {
      label: 'Survey#',
      orderKey: 'ps_survey_id',
    },
    {
      label: 'Survey Title',
      orderKey: 'survey_title',
    },
    {
      label: 'Survey Type',
      orderKey: 'survey_type',
    },
    {
      label: 'Launched',
      orderKey: 'creation_date',
    },
    {
      label: 'Fielded Vs Goal',
      orderKey: 'fielded',
    },
    {
      label: 'Avg. CPI',
      orderKey: 'avg_cpi',
    },
    {
      label: 'Current CPI',
      orderKey: 'cpi_current',
    },
    {
      label: 'EPC',
      orderKey: 'epc',
    },
    {
      label: 'Current IR',
      orderKey: 'current_ir',
    },
    {
      label: 'Total Starts',
      orderKey: 'total_starts',
    },
    {
      label: 'Starts 24hrs',
      orderKey: 'last_24_starts',
    },
    {
      label: 'In Progress',
      orderKey: 'in_progress',
    },
  ],
  STATUS_CHANGE_MODAL: {
    MODAL_CLOSE_BTN_TEXT: {
      Paused: 'No. DO NOT PAUSE this project.',
      Live: 'No. DO NOT make this project LIVE.',
      Closed: 'No. DO NOT CLOSE this project.',
      Invoice: 'No. DO NOT INVOICE this project.',
    },
  },
  MARGIN_REPORT_OPTIONS: [
    {
      label: 'Survey Margin',
      value: 'survey_margin',
    },
    {
      label: 'Project Margin',
      value: 'project_margin',
    },
    {
      label: 'Overall Margin',
      value: 'overall_margin',
    },
    {
      label: 'Project Overall Margin',
      value: 'project_overall_margin',
    },
    {
      label: 'Supplier Cost',
      value: 'supplier_cost',
    },
  ],
  CHILD_CREATION_STATUS: {
    PENDING: 0,
    READY: 1,
    ERROR: 2,
  },
  HEALTH_CHECK: {
    DEFAULT_COUNT: <HealthCheckCount>{
      GREY: 0,
      GREEN: 0,
      YELLOW: 0,
      RED: 0,
    },
  },
});

export const WIDGETS: Record<string, string> = Object.freeze({
  traffic: 'trafficChannels',
  multiCountry: 'multiCountry',
});

export const USER_DASHBOARD_VIEW_OBJECT: Record<string, number> = Object.freeze(
  {
    NONE: 0,
    VIEW_MY_PROJECTS_BUYER: 1,
    VIEW_MY_PROJECTS_OPERATOR: 2,
    VIEW_BY_PM: 3,
    VIEW_UNASSIGNED: 4,
    VIEW_BY_OPERATOR: 5,
  }
);

export const DEFAULT_SELECTED_SURVEY: SurveyData = Object.freeze({
  currencySymbol: '',
  fielded: 0,
  field_date_info: {
    creation_date: 0,
    days_in_field: 0,
    days_remaining: 0,
    end_date: 0,
  },
  reconciliationCompleteCount: 0,
  manualFixCompltCount: 0,
  totalCostLabel: '',
  totalCostValue: undefined,
  goal: 0,
  totalCost: {
    launch: undefined,
    projected: 0,
    current: 0,
  },
  cpi1Label: '',
  cpi1Value: undefined,
  cpi2Label: '',
  cpi2Value: undefined,
  cpi: {
    current: 0,
    launch: 0,
    projected: undefined,
    averageCpi: 0,
    averageACpi: 0,
  },
  loiLabel: '',
  loiValue: 0,
  loi: {
    launch: 0,
    overall: 0,
    recent: 0,
  },
  incidenceLabel: '',
  incidenceValue: 0,
  incidence: {
    launch: 0,
    overall: 0,
    recent: 0,
  },
  companyId: 0,
  languageName: '',
  is_qbp_child_survey: false,
  click_balancing: 0,
  qbp: {
    enabled: false,
    surveyId: 0,
    selectedQuotaIds: [],
    quota_ids: [],
    category: '',
    cpi: 0,
  },
  is_qbp_enable: undefined,
});

export const DEFAULT_STATS_SURVEY: SurveyStats = Object.freeze({
  fielded: 654,
  manual_fix_complt_count: 0,
  rec_complt_count: 637,
  goal: 200,
  currency_symbol: '$',
  total_cost: {
    current: 35970,
    launch: 15507,
    projected: 15123.905,
  },
  cpi: {
    current: 156.005,
    launch: 155.07,
    projected: 151.24,
    averageCpi: 0,
    averageACpi: 0,
  },
  loi: {
    recent: 10,
    launch: 10,
    overall: 10,
  },
});
