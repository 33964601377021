import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { BILLING_NUMBER } from '../../../constants/billing-number-rules';
import { notifyMessage } from '../../../constants/notify-message';
import { ToasterService } from '@purespectrum1/ui/toaster-service';

import {
  CharacterType,
  CharacterRuleType,
  BillingRules,
  Rules,
} from '../../../shared/interfaces/billing-rules.interface';
import { BuyerSettingsService } from '../../buyer-settings-service/buyer-settings-service.service';
import { of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { BillingNumberTooltip, IndividualConfig } from '@purespectrum1/ui';
import { BillingNumberTooltipInfo } from '@purespectrum1/ui/marketplace/shared/services/billing-rules/domain/types';

@Component({
  selector: 'ps-billing-number-rules',
  templateUrl: './billing-number-rules.component.html',
  styleUrls: ['./billing-number-rules.component.css'],
})
export class BillingNumberRulesComponent implements OnInit {
  CharacterType = CharacterType;
  entryTypeDropdownValues!: CharacterRuleType[];
  billingForm!: FormGroup;
  public billingRules: FormArray = this._fb.array([]);
  totalEnteredCharactes: number = 0;
  isRowEditable: boolean = false;
  maxChar: number = -1;
  private _id: number = 0;
  public billingNumberRules?: BillingRules;
  public specialChars = '“{ } [ ] ( ) /  ~ - , ; : . < > _ * + & # @“';
  public structure: BillingNumberTooltipInfo[] = [];
  public example = '';

  constructor(
    private _toastr: ToasterService,
    private _fb: FormBuilder,
    private _buyerSettingsService: BuyerSettingsService
  ) {}

  get billingRulesArray() {
    return this.billingForm.get('rules') as FormArray;
  }

  get billingMinMax() {
    const length = this.billingForm.get('length')?.value;
    return {
      min: length?.min,
      max: length?.max,
    };
  }

  get isMaximumCharacterLimitReached() {
    return this.totalEnteredCharactes === Number(this.billingMinMax.max);
  }

  get characterRemainLabel() {
    if (this.isMaximumCharacterLimitReached) {
      return 'None';
    }
    return `from ${this.totalEnteredCharactes + 1} to ${
      this.billingMinMax.max
    }`;
  }

  requiredStatus(index: number) {
    if (index === this.billingRulesArray.length - 1) {
      this.updateRequiredStatus(index, true, true); // 1st row must be required
    }
    return this.billingRulesArray.at(index).get('required')?.value;
  }

  updateRequiredStatus(
    index: number,
    value: boolean,
    isFirstRow: boolean = false
  ) {
    if (value && !isFirstRow) {
      for (let i = index; i < this.billingRulesArray.length; i++) {
        this.billingRulesArray.at(i).patchValue({ required: value });
      }
    } else if (!value && !isFirstRow) {
      for (let i = 0; i < index; i++) {
        this.billingRulesArray.at(i).patchValue({ required: value });
      }
    }
    this.billingRulesArray.at(index).patchValue({ required: value });
  }

  editRow(index: number) {
    this.isRowEditable = this.isEditButtonVisible(index);
  }

  ngOnInit() {
    this.entryTypeDropdownValues = [
      {
        label: 'User Entry',
        value: 'input',
      },
      {
        label: 'Fixed',
        value: 'fixed',
      },
    ];

    this._generateForm();

    this._buyerSettingsService
      .getBillingRules()
      .pipe(
        tap((rules) => {
          this.billingNumberRules = rules;
          this._updateTooltipSettings(rules);
        }),
        tap(() => this.deleteRuleRow(0)),
        tap(({ length, mandatory, autoGenerate }) =>
          this.billingForm.patchValue({ length, mandatory, autoGenerate })
        ),
        map(({ rules, ...rest }) => ({
          ...rest,
          rules: rules.map((rule) => this._mapRuleEntry(rule)),
        })),
        tap(({ rules }) =>
          rules.reverse().forEach((rule) => this.insertNewRow(rule.range.start))
        ),
        tap(({ rules }) => this.billingForm.patchValue({ rules }))
      )
      .subscribe(({ length, id }) => {
        this._id = id || 0;
        this.totalEnteredCharactes = length.max;
        this.maxChar = length.max;
        this.insertNewRow(this.maxChar + 1);
      });
  }

  private _mapRuleEntry(rule: Rules) {
    const type = this.entryTypeDropdownValues.find(
      (entry) => entry.value === rule.entry
    );
    return { ...rule, type };
  }

  private _generateForm() {
    this.billingForm = this._fb.group({
      length: this._fb.group({
        min: [
          BILLING_NUMBER.MIN_CHAR,
          [
            Validators.required,
            Validators.min(BILLING_NUMBER.MIN_CHAR),
            Validators.max(BILLING_NUMBER.MAX_CHAR),
          ],
        ],
        max: [
          BILLING_NUMBER.MAX_CHAR,
          [
            Validators.required,
            Validators.min(BILLING_NUMBER.MIN_CHAR),
            Validators.max(BILLING_NUMBER.MAX_CHAR),
          ],
        ],
      }),
      mandatory: [false, [Validators.required]],
      rules: this.billingRules,
      autoGenerate: [false],
    });
    this.initializeFormArray();
  }

  initializeFormArray() {
    this.insertNewRow();
  }

  insertNewRow(startMin: number | undefined = undefined) {
    let initialValue: number | null = 1;
    let endValue =
      this.billingRulesArray.length === 0 ? this.billingMinMax.min : null;
    if (startMin) {
      initialValue = startMin;
    }
    if (
      this.billingMinMax.max === BILLING_NUMBER.MAX_CHAR &&
      this.billingRulesArray.length > 1 &&
      this.isMaximumCharacterLimitReached
    ) {
      initialValue = null;
    }

    const newFormElement = this._fb.group({
      range: this._fb.group({
        start: [initialValue, [Validators.required]],
        end: [
          endValue,
          [Validators.required, Validators.max(BILLING_NUMBER.MAX_CHAR)],
        ],
      }),
      entry: [null, Validators.required], // value will be fixed or userEntry
      type: [null],
      required: [false, Validators.required],
      definition: [null],
      char: [[]],
    });

    this.billingRulesArray.insert(0, newFormElement);
  }

  addNewRuleRow(index: number): void {
    const formValue = this.billingRulesArray.at(index);
    const fixedRuleControl = formValue.get('definition');
    const isFormValid = this.validateBillingRules(index);
    if (!isFormValid) {
      return;
    }
    if (!formValue.valid) {
      if (
        this.isEntryTypeFixed(index) &&
        fixedRuleControl?.hasError('invalidCharacters')
      ) {
        this._toastr.error(
          notifyMessage.errorMessage.FIXED_RULE_INVALID_CHARACTER
        );
        return;
      }

      this._toastr.error(
        notifyMessage.errorMessage.BUYER_SETTINGS.PO_RULES
          .BILLING_RULES_CANNOT_BE_EMPTY,
        notifyMessage.errorMessage.BUYER_SETTINGS.PO_RULES
          .INCOMPLETE_INPUT_TITLE
      );
      return;
    }
    const currentMax = Number(formValue.get('range')!.get('end')?.value) + 1;
    this.totalEnteredCharactes += this.ruleMinMaxDifferance(index);
    const tempIndex = this.billingRulesArray.length - 1;
    if (this.isRowEditable) {
      this.billingRulesArray.at(index).patchValue(formValue.value);
      this.isRowEditable = false;
      this.totalEnteredCharactes = this.ruleMinMaxDifferance(index);
      return;
    }

    if (tempIndex === 0) {
      this.totalEnteredCharactes = this.ruleMinMaxDifferance(index);
    }
    isFormValid && this.insertNewRow(currentMax);

    if (this.billingRulesArray.length > 2 && this.maxChar === -1) {
      this.maxChar = this.billingMinMax.max;
    }
  }

  deleteRuleRow(index: number): void {
    this.totalEnteredCharactes -= this.ruleMinMaxDifferance(index);
    this.billingRulesArray.removeAt(index);
    this.updateMinValues(index);
    if (this.billingRulesArray.length <= 2) {
      this.maxChar = -1;
    }
  }

  updateEntryTypesValue(index: number, entryType: any) {
    const formValue = this.billingRulesArray.at(index);
    const charType = formValue.get('char');
    const definition = formValue.get('definition');

    const maxLength = formValue?.get('range')!.get('end')?.value;
    const minLength = formValue?.get('range')!.get('start')?.value;

    // checking min max is filled or not
    if (!maxLength || !minLength) {
      this._toastr.error(
        notifyMessage.errorMessage.BUYER_SETTINGS.PO_RULES
          .BILLING_RULES_CANNOT_BE_EMPTY
      );
      return;
    }
    const fixedRuleMaxLength = Number(maxLength) + 1 - Number(minLength);

    if (entryType.value === this.entryTypeDropdownValues[1].value) {
      // for fiexed
      charType?.reset([]);
      charType?.clearValidators();

      const fixedRuleError = {
        invalidCharactersError: true,
        message: notifyMessage.errorMessage.FIXED_RULE_INVALID_CHARACTER,
      };

      definition?.setErrors(fixedRuleError);
      definition?.setValidators([
        Validators.required,
        Validators.maxLength(fixedRuleMaxLength),
        Validators.minLength(fixedRuleMaxLength),
        this.fixedRuleAllowedCharactersValidator(),
      ]);

      formValue.patchValue({
        char: [
          CharacterType.Alpha,
          CharacterType.Special,
          CharacterType.Numeric,
        ],
      });
    } else if (entryType.value === this.entryTypeDropdownValues[0].value) {
      // for userEntry
      charType?.setValidators([Validators.required]);
      definition?.reset();
      definition?.clearValidators();
    }

    definition?.updateValueAndValidity();
    charType?.updateValueAndValidity();
    this.billingRulesArray.at(index).patchValue({ entry: entryType.value });
  }

  isEntryTypeFixed(index: number) {
    return this.billingRulesArray.at(index).get('entry')?.value === 'fixed';
  }

  isEditButtonVisible(index: number) {
    return index === 1 && this.billingRulesArray.length === 2;
  }

  updateChar(characterType: CharacterType, index: number) {
    const charArray = this.billingRulesArray.at(index).get('char')
      ?.value as CharacterType[];

    if (this.isCheckboxSelected(characterType, index)) {
      const i = charArray.indexOf(characterType);
      if (i !== -1) {
        charArray.splice(i, 1);
      }
    } else {
      charArray.push(characterType);
    }
    this.billingRulesArray.at(index).get('char')?.setValue(charArray);
  }

  isCheckboxSelected(characterType: CharacterType, index: number): boolean {
    const charArray = this.billingRulesArray.at(index).get('char')
      ?.value as CharacterType[];
    return charArray.includes(characterType);
  }

  ruleMinMaxDifferance(index: number) {
    const formValue = this.billingRulesArray.at(index).get('range');
    const end = Number(formValue!.get('end')?.value) + 1;
    const start = Number(formValue!.get('start')?.value);
    return end - start;
  }

  // validations
  validateBillingRules(index: number) {
    const formValue = this.billingRulesArray.at(index);
    const isMinValid = this.validateBillingRuleMinMaxInput(
      index,
      false,
      false,
      formValue.get('range')!.get('start')?.value
    );
    const isMaxValid = this.validateBillingRuleMinMaxInput(
      index,
      false,
      false,
      formValue.get('range')!.get('end')?.value
    );
    if (this.isEntryTypeFixed(index)) {
      const isFixedRuleValid = this.validateBillingRuleMinMaxInput(
        index,
        false,
        true,
        formValue.get('definition')?.value
      );
      return isMinValid && isMaxValid && isFixedRuleValid;
    }
    return isMinValid && isMaxValid;
  }

  validateBillingRuleMinMaxInput(
    index: number,
    event: any,
    isFixedRule: boolean = false,
    currentRuleMinMax: number = -1
  ): boolean {
    const minMaxValue =
      currentRuleMinMax !== -1 && !event
        ? currentRuleMinMax
        : event.target.value;

    if (isFixedRule) {
      const diff = this.ruleMinMaxDifferance(index);
      if (diff !== minMaxValue?.length) {
        this._toastr.error(
          notifyMessage.errorMessage.BUYER_SETTINGS.PO_RULES
            .FIXED_RULE_UNDERFLOW_OVERFLOW,
          notifyMessage.errorMessage.BUYER_SETTINGS.PO_RULES
            .FIXED_CHARACTER_INVALID_RANGE_TITLE
        );
        return false;
      }
      return true;
    }
    if (this.isEditButtonVisible(index)) {
      const { start = 0, end = 0 } =
        this.billingRulesArray.controls[1].value.range;
      if (
        Number(end) >= Number(this.billingMinMax.max) ||
        Number(end) <= Number(start)
      ) {
        this._toastr.error(
          notifyMessage.errorMessage.BUYER_SETTINGS.PO_RULES
            .BILLING_NUMBER_MIN_MAX_INVALID_RANGE,
          notifyMessage.errorMessage.BUYER_SETTINGS.PO_RULES.INVALID_RULE_TITLE
        );
        return true;
      }
      this.updateMinRangeValue(index, Number(end));
      return true;
    }

    const isOverLap = this._isRangeOverlap(0, Number(minMaxValue));
    if (isOverLap) {
      this._toastr.error(
        notifyMessage.errorMessage.BUYER_SETTINGS.PO_RULES
          .BILLING_NUMBER_MIN_MAX_INVALID_RANGE,
        notifyMessage.errorMessage.BUYER_SETTINGS.PO_RULES.INVALID_RULE_TITLE
      );
    }
    return !isOverLap;
  }

  private _isRangeOverlap(index: number, value: number) {
    let overlap = false;
    this.billingRulesArray.controls.forEach((element, currentIndex) => {
      if (
        (index !== currentIndex &&
          value >= Number(element.value?.range?.start) &&
          value <= Number(element.value?.range?.end)) ||
        value > Number(this.billingMinMax.max)
      ) {
        overlap = true;
      }
    });
    return overlap;
  }

  checkIndex(
    index: number,
    operator: keyof Comparisons,
    compareNumber: number
  ) {
    const comparisons: Comparisons = {
      '===': index === compareNumber,
      '>': index > compareNumber,
      '<': index < compareNumber,
      // Add more operators and their return values here
    };

    if (operator in comparisons) {
      return comparisons[operator];
    }

    throw new Error('Invalid operator');
  }

  isDisableFirstRow() {
    const { length } = this.billingForm.value || { length: { max: 0 } };
    return this.billingRulesArray.length > length?.max;
  }

  validateBillingNumberChar(
    event: Event,
    isFromMin: boolean = false,
    isFromMax: boolean = false
  ) {
    if (
      isFromMax &&
      parseInt((event.target as HTMLInputElement).value) < this.maxChar
    ) {
      this._toastr.error(
        notifyMessage.errorMessage.BUYER_SETTINGS.PO_RULES
          .LENGTH_MAX_CHAR_INVALID
      );
      this.billingForm.patchValue({ length: { max: this.maxChar } });
      return;
    }

    if (
      parseInt((event.target as HTMLInputElement).value) <
      BILLING_NUMBER.MIN_CHAR
    ) {
      this._toastr.error(
        notifyMessage.errorMessage.BUYER_SETTINGS.PO_RULES.BILLING_NUMBER_MIN_CHAR.replace(
          ':CHAR',
          `${BILLING_NUMBER.MIN_CHAR}`
        ),
        notifyMessage.errorMessage.BUYER_SETTINGS.PO_RULES.INVALID_MINIMUM_RANGE_TITLE.replace(
          ':CHAR',
          parseInt((event.target as HTMLInputElement).value).toString()
        )
      );
    }
    if (
      parseInt((event.target as HTMLInputElement).value) >
      BILLING_NUMBER.MAX_CHAR
    ) {
      this._toastr.error(
        notifyMessage.errorMessage.BUYER_SETTINGS.PO_RULES.BILLING_NUMBER_MAX_CHAR.replace(
          ':CHAR',
          `${BILLING_NUMBER.MAX_CHAR}`
        )
      );
      this.billingForm.patchValue({ length: { max: BILLING_NUMBER.MAX_CHAR } });
    }
    const validateBillingRange = this._validateBillingRange();
    if (validateBillingRange) {
      this._toastr.error(
        notifyMessage.errorMessage.BUYER_SETTINGS.PO_RULES
          .BILLING_NUMBER_RANGE_INVALID,
        notifyMessage.errorMessage.BUYER_SETTINGS.PO_RULES.NON_SEQUENTIAL_TITLE
      );
    }

    // updating default range
    // index 1 for 1st row
    if (isFromMin && this.billingMinMax.min >= 1) {
      const index = this.billingRulesArray.length - 1;
      this.billingRulesArray
        .at(index)
        .patchValue({ range: { end: this.billingMinMax.min } });
      this.updateMinValues(1);
      // updating the total Entered charater
      const tempIndex = this.billingRulesArray.length === 1 ? 0 : 1;
      const diffValue = this.ruleMinMaxDifferance(tempIndex);
      this.totalEnteredCharactes = diffValue;
      if (this.isEntryTypeFixed(tempIndex)) {
        this.updateEntryTypesValue(tempIndex, this.entryTypeDropdownValues[1]);
      }
      if (index > 0) {
        this.isRowEditable = true;
      }
    }
  }

  private _validateBillingRange() {
    if (
      this.billingForm.value?.length?.min &&
      this.billingForm.value?.length?.max
    ) {
      return !!(
        this.billingForm.value?.length?.min >
        this.billingForm.value?.length?.max
      );
    }
    return false;
  }

  setBillingNumberRequired(flag: boolean) {
    this.billingForm.patchValue({ mandatory: flag });

    if (this._id !== 0) {
      this.saveRules();
    }
  }

  updateMinValues(index: number) {
    if (this.billingRulesArray.length > 1) {
      const value =
        Number(this.billingRulesArray.at(index).value?.range?.end) + 1;
      this.billingRulesArray
        .at(index - 1)
        .patchValue({ range: { start: value } });
    }
  }

  // save billing rules
  saveRules() {
    this.validateFormRange()
      .pipe(
        switchMap(() => {
          const validPayload = this.prepairValidPayload();

          if (this._id !== 0) {
            return this._buyerSettingsService.updateBuyerBillingRules(
              this._id,
              validPayload
            );
          }

          return this._buyerSettingsService.createBuyerBillingRules(
            validPayload
          );
        }),
        catchError((err) => {
          const errorMessage =
            err?.error?.ps_api_response_message || err.message;
          this._toastr.error(errorMessage, '', {
            enableHtml: true,
          } as IndividualConfig);
          return throwError(err); // // Re-throw the error to propagate it downstream if needed
        })
      )
      .subscribe((res) => {
        this.billingNumberRules = res;
        this._updateTooltipSettings(res);
        this._id = res.id;

        this._toastr.success(
          notifyMessage.successMessage.BUYER_SETTINGS.VARIABLE_MAPPING
            .SAVE_SETTING
        );
      });
  }

  // validate of payload
  validateFormRange() {
    if (!this.isMaximumCharacterLimitReached) {
      const errMessage =
        notifyMessage.errorMessage.BUYER_SETTINGS.PO_RULES.BILLING_RULE_REQUIRED_CHARACTERS_LENGTH.replace(
          ':MIN_CHAR',
          this.billingMinMax.min
        ).replace(':MAX_CHAR', this.billingMinMax.max);

      return throwError(new Error(errMessage));
    }
    return of(null);
  }

  prepairValidPayload() {
    const payload: BillingRules = JSON.parse(
      JSON.stringify(this.billingForm.value)
    );
    payload.rules.shift();
    payload.rules.reverse();
    return payload;
  }

  // custom validator
  fixedRuleAllowedCharactersValidator(): ValidatorFn {
    const pattern = /^(?:[a-zA-Z]|[0-9]|[{}\[\]()\/\\~,\-;:.<>_*+&#@])*$/;

    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value || pattern.test(value)) {
        return null; // Valid input
      }
      return { invalidCharacters: true }; // Invalid input
    };
  }

  setAutoGenerateBillingNumberFlag(flag: boolean) {
    this.billingForm.patchValue({ autoGenerate: flag });

    if (this._id !== 0) {
      this.saveRules();
    }
  }

  updateMinRangeValue(index: number, max: number) {
    this.updateMinValues(index);
    this.billingForm.patchValue({ length: { min: max } });
    const tempIndex = this.billingRulesArray.length === 1 ? 0 : 1;
    const diffValue = this.ruleMinMaxDifferance(tempIndex);
    this.totalEnteredCharactes = diffValue;
  }

  private _updateTooltipSettings(rules: BillingRules) {
    if (rules) {
      const billingNumberInfo = new BillingNumberTooltip(rules);
      this.structure = billingNumberInfo.structure();
      this.example = billingNumberInfo.example();
    }
  }
}

type Comparisons = {
  '===': boolean;
  '>': boolean;
  '<': boolean;
  // Add more operators and their return types as needed
};
