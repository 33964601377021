import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ToasterService } from '@purespectrum1/ui/toaster-service';

import { CompanyService } from '../../shared/services/company/company.service';
import { SupplierSettingService } from '../supplier-setting-service/supplier-setting.service';
import { CompaniesResponse } from '../../shared/interfaces/company.interface';
import { Constants } from './supplier-settings-constants';
import {
  ConfigFields,
  SelectedSupplierSettings,
  SingleSupplier,
  SupplierSettingsResponse,
} from './supplier-settings.interface';

@Component({
  selector: 'ps-supplier-settings',
  templateUrl: './supplier-settings.component.html',
  styleUrls: ['./supplier-settings.component.css'],
})
export class SupplierSettingsComponent implements OnInit, OnDestroy {
  public suppliers: SingleSupplier[] = [];
  public selectedSupplier!: SingleSupplier;
  public supplierFields!: ConfigFields[];
  public selectedSupplierConfig!: SelectedSupplierSettings;
  private _subscription: Subscription = new Subscription();
  public microSurveyForm!: FormGroup;
  constructor(
    private _companyService: CompanyService,
    private _supplierSettingService: SupplierSettingService,
    private _toastrService: ToasterService,
    private _fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this._generateForm();
    this._getSuppliers();
  }

  _getSuppliers() {
    const suppliers$ = this._companyService.getSupplierCompanies().subscribe(
      (response: CompaniesResponse) => {
        this.suppliers = response.companies;
      },
      (error) => {
        this._toastrService.error(error.error.msg);
      }
    );
    this._subscription.add(suppliers$);
  }

  _generateForm() {
    this.microSurveyForm = this._fb.group({
      active: false,
      showMicrosurveySettings: false,
      showConsent: false,
      currencyName: ['', Validators.required],
      conversionValue: [null, Validators.required],
      rewardAmount: [null, Validators.required],
    });
  }

  _buildUpdatePayload(supplierId: number, value: boolean, settingType: string) {
    const mapper: any = {
      supplierType: (_value: boolean) => {
        return {
          supplier_type: _value ? 'private' : 'public',
        };
      },
      dynamicProfiler: (_value: boolean) => {
        return {
          dynamicProfiler: !!_value,
        };
      },
      isPrivacyPolicyActive: (_value: boolean) => {
        return {
          isPrivacyPolicyActive: !!_value,
        };
      },
      isDataQualityQuestionsActive: (_value: boolean) => {
        return {
          isDataQualityQuestionsActive: !!_value,
        };
      },
      isAsrRedirectionEnable: (_value: boolean) => {
        return {
          isAsrRedirectionEnable: !!_value,
        };
      },
      skipPreScreener: (_value: boolean) => {
        return {
          skipPreScreener: !!_value,
        };
      },
    };
    if (!mapper[settingType]) {
      this._toastrService.error('Something went wrong in mapping!');
    }
    return Object.assign({}, mapper[settingType](value), { cmp: supplierId });
  }
  /**
   * on change of any setting, this function invoked from html and then setting saved in db with service invocation.
   * @param supplierId supplier id - unique id for each supplier
   * @param value each setting has boolean value
   * @param settingType setting flag which used to send with PUT supplier setting api
   */
  changeSupplierSetting(
    supplierId: number,
    value: boolean,
    settingType: string
  ) {
    const payload = this._buildUpdatePayload(supplierId, value, settingType);
    const updateSetting$ = this._supplierSettingService
      .updateSupplierSetting(supplierId, payload)
      .subscribe(
        (response) => {
          this._toastrService.success(response.msg);
        },
        (error) => {
          this._toastrService.error(error.error.msg);
        }
      );
    this._subscription.add(updateSetting$);
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  selectSupplier(supplier: SingleSupplier) {
    this._getSupplierSettings(supplier.id);
    this.selectedSupplier = supplier;
  }

  _getSupplierSettings(supplierId: number) {
    this.supplierFields = [];
    this._supplierSettingService.getSupplierSettings(supplierId).subscribe(
      (response: SupplierSettingsResponse) => {
        if (response.supplier && response.supplier.length) {
          this.selectedSupplierConfig = {
            ...response.supplier[0],
            supplierType:
              response.supplier[0].supplier_type === 'public' ? false : true,
          };
          if (response.supplier[0]?.microSurveySetting) {
            this.microSurveyForm?.patchValue(
              response.supplier[0].microSurveySetting
            );
          } else {
            this.microSurveyForm.reset({
              active: false,
              showMicrosurveySettings: false,
              showConsent: false,
              currencyName: '',
              conversionValue: null,
              rewardAmount: null,
            });
          }
          this.supplierFields = Constants.SUPPLIER_FIELDS;
        }
      },
      (error: any) => {
        this._toastrService.error(error.error.msg);
      }
    );
  }

  updateSupplierSettings(value: boolean, config: string, settingType: string) {
    if (settingType !== 'MICROSURVEY_SETTINGS') {
      return this.changeSupplierSetting(
        this.selectedSupplier.id,
        value,
        config
      );
    }
    this.microSurveyForm?.patchValue({ [config]: value });
  }

  showInput(value: string) {
    const inputRequiredFields = [
      'currencyName',
      'conversionValue',
      'rewardAmount',
    ];
    return inputRequiredFields.includes(value);
  }

  saveMicroSurveySettings() {
    if (this.microSurveyForm?.invalid) {
      this._toastrService.error('Please fill all the required fields');
      return;
    }
    const microSurveySetting = this.microSurveyForm?.value;
    microSurveySetting.conversionValue = parseFloat(
      microSurveySetting.conversionValue
    );
    microSurveySetting.rewardAmount = parseFloat(
      microSurveySetting.rewardAmount
    );
    const payload = {
      cmp: this.selectedSupplier.id,
      microSurveySetting: microSurveySetting,
    };
    const updateSetting$ = this._supplierSettingService
      .updateSupplierSetting(this.selectedSupplier.id, payload)
      .subscribe(
        (response) => {
          this._toastrService.success(response.msg);
        },
        (error) => {
          this._toastrService.error(error.error.msg);
        }
      );
    this._subscription.add(updateSetting$);
  }
}
