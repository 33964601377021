import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class OperatorLevelGuard implements CanActivate {
  constructor(private _router: Router, private _user: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const { data = {} } = route;
    const roles = data.operatorLevel || [];
    if (this._isOperator(this._user.userType)) {
      if (roles.includes(this._user.user?.operatorAcssLvls)) {
        return true;
      }
      return this._router.parseUrl('/access-denied');
    }
    return true;
  }

  _isOperator(userType: string) {
    return userType === 'operator';
  }
}
