<div class="supplier-tabs" [formGroup]="form">
  <div formGroupName="microSurveySetting">
    <!-- ACTIVE (on/off) -->
    <div class="form-group">
      <label class="col-md-4 control-label">Micro Surveys (Active)</label>
      <div class="col-md-6">
        <label class="radio-inline">
          <input
            type="radio"
            name="active"
            [value]="true"
            formControlName="active"
          />
          Yes
        </label>
        <label class="radio-inline">
          <input
            type="radio"
            name="active"
            [value]="false"
            formControlName="active"
          />
          No
        </label>
      </div>
    </div>

    <!-- SHOW MESSAGE -->
    <div class="form-group">
      <label class="control-label col-md-4">Show Consent</label>
      <div class="col-md-6">
        <label class="radio-inline">
          <input
            type="radio"
            name="showConsent"
            [value]="true"
            formControlName="showConsent"
          />
          Yes
        </label>
        <label class="radio-inline">
          <input
            type="radio"
            name="showConsent"
            [value]="false"
            formControlName="showConsent"
          />
          No
        </label>
      </div>
    </div>

    <!-- CURRENCY NAME -->
    <div class="form-group">
      <label class="control-label col-md-4">Currency Name</label>
      <div class="col-md-6">
        <input
          type="text"
          class="form-control input-md"
          placeholder="e.g. points"
          formControlName="currencyName"
        />
      </div>
    </div>

    <!-- CONVERSION VALUE -->
    <div class="form-group">
      <label class="control-label col-md-4">Conversion Value</label>
      <div class="col-md-6">
        <input
          type="number"
          class="form-control input-md"
          placeholder="100"
          formControlName="conversionValue"
        />
      </div>
    </div>

    <!-- REWARD AMOUNT -->
    <div class="form-group">
      <label class="control-label col-md-4">Reward Amount</label>
      <div class="col-md-6">
        <input
          type="number"
          class="form-control input-md"
          placeholder="0.02"
          formControlName="rewardAmount"
          step="any"
        />
      </div>
    </div>
  </div>
</div>
