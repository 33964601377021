'use strict';

export const Constants = Object.freeze({
  TRANS_STATUS_LIST: [
    { name: 'PS Drop', id: 11 },
    { name: 'PS QuotaFull Core', id: 12 },
    { name: 'PS Termination Core', id: 13 },
    { name: 'PS Side In Progress', id: 14 },
    { name: 'PS Quality', id: 15 },
    { name: 'Buyer side In Progress', id: 16 },
    { name: 'Buyer QuotaFull', id: 17 },
    { name: 'Buyer Termination', id: 18 },
    { name: 'Buyer Drop', id: 19 },
    { name: 'Buyer Quality Termination', id: 20 },
    { name: 'Complete', id: 21 },
    { name: 'PS Survey Closed Termination', id: 22 },
    { name: 'PS Survey Paused Termination', id: 23 },
    { name: 'PS Unopened Quota Term', id: 24 },
    { name: 'PS Supplier Allocation Full', id: 25 },
    { name: 'PS Past Participation Fail', id: 26 },
    { name: 'PS Supplier Quota Allocation Full', id: 27 },
    { name: 'PS_Invalid_Survey', id: 28 },
    { name: 'PS LOI Threshold Failure', id: 29 },
    { name: 'Buyer Security', id: 30 },
    { name: 'Buyer Hash Security', id: 31 },
    { name: 'PS Grouping Termination', id: 32 },
    { name: 'Buyer Reconciliation Reject', id: 33 },
    { name: 'PS Temp Exclusion', id: 34 },
    { name: 'PS No Matched Quotas', id: 35 },
    { name: 'PS Max Ip Throttling Termination', id: 36 },
    { name: 'PS Quota Throttling Termination', id: 37 },
    { name: 'PS PSID Geo Termination', id: 38 },
    { name: 'PS TrueSample Fail', id: 39 },
    { name: 'PS GeoIP Fail', id: 40 },
    { name: 'PS Bot Fail', id: 41 },
    { name: 'PS BlackList Fail', id: 42 },
    { name: 'PS Anonymous Fail', id: 43 },
    { name: 'PS Termination Extended', id: 45 },
    { name: 'PS Termination Custom', id: 46 },
    { name: 'PS QuotaFull Extended', id: 47 },
    { name: 'PS QuotaFull Custom', id: 48 },
    { name: 'PS Include Fail', id: 49 },
    { name: 'PS Exclude Fail', id: 50 },
    { name: 'INVALID SUPPLIER', id: 51 },
    { name: 'PSID SERVICE FAIL', id: 52 },
    { name: 'Screener In Progress', id: 53 },
    { name: 'PS_RVID_Hash_Fail', id: 54 },
    { name: 'PS_Unique_Link_Termination', id: 55 },
    { name: 'PS_Unauthorized_Augment', id: 56 },
    { name: 'PS_Supplier_Quotafull', id: 57 },
    { name: 'PS_Supplier_Quota_Throttling_Termination', id: 58 },
    { name: 'PS_Buyer_Config_Error', id: 59 },
    { name: 'PS_Js_Fail', id: 60 },
    { name: 'PS_QBP_Grouping_Termination', id: 61 },
    { name: 'PS_NoPureScore', id: 62 },
    { name: 'PS_Blacklist_Data_Quality', id: 63 },
    { name: 'PS_Blacklist_Data_Quality_2', id: 64 },
    { name: 'PS_Rvid_IPStatus_Fail', id: 65 },
    { name: 'PS_Rvid_Fraudrisk_Fail', id: 66 },
    { name: 'PS_SC_Fraudster_Fail', id: 67 },
    { name: 'PS_SC_Threat_Fail', id: 68 },
    { name: 'PS_DF_Dupe', id: 70 },
    { name: 'PS_SC_Sec_Hash_Fail', id: 71 },
    { name: 'PS_Blacklist_Data_Quality_3', id: 72 },
    { name: 'PS_Transaction_Fraud', id: 73 },
    { name: 'Buyer_Eligibility_Check_Fail', id: 74 },
    { name: 'PS_Blacklist_Data_Quality_4', id: 75 },
    { name: 'PS_DQ_Honeypot_Fail', id: 78 },
    { name: 'PS_PureText_Dedupe_Fail', id: 79 },
    { name: 'PS_AI_Text_Fail', id: 80 },
    { name: 'PS_Puretext_Language_Fail', id: 81 },
    { name: 'PS_Survey_Signature_Fail', id: 82 },
    { name: 'PS_Browser_Manipulation_Fail', id: 83 },
    { name: 'Buyer_PS_API_Fail', id: 84 },
    { name: 'PS_RD_Predupe', id: 85 },
    { name: 'PS_RD_Dedupe_Grouping_Termination', id: 86 },
  ],
  TRANSACTION_STATUS: {
    BUYER_SIDE_IN_PROGRESS: 16,
    BUYER_QUOTAFULL: 17,
    BUYER_TERMINATION: 18,
    BUYER_DROP: 19,
    BUYER_QUALITY_TERMINATION: 20,
    COMPLETE: 21,
    BUYER_SECURITY: 30,
    BUYER_HASH_SECURITY: 31,
    BUYER_RECONCILIATION_REJECT: 33,
  },
  REPORTS_TAB: [
    {
      title: 'TRANSACTION REPORT',
      uniqueId: 'transaction',
      active: true,
    },
    {
      title: 'STATEMENT REPORT',
      uniqueId: 'statement',
      active: false,
    },
    // PD-9514 - REVERSAL REPORT hidden on prod
    // {
    //   title: 'REVERSAL REPORT',
    //   uniqueId: 'reversal',
    //   active: false,
    // },
    {
      title: 'REWARD REPORT',
      uniqueId: 'reward',
      active: false,
    },
  ],
});

export enum PSStatusList {
  PS_DROP = 11,
  PS_QUOTAFULL_CORE = 12,
  PS_TERMINATION_CORE = 13,
  PS_SIDE_IN_PROGRESS = 14,
  PS_QUALITY = 15,
  PS_SURVEY_CLOSED_TERMINATION = 22,
  PS_SURVEY_PAUSED_TERMINATION = 23,
  PS_UNOPENED_QUOTA_TERM = 24,
  PS_SUPPLIER_ALLOCATION_FULL = 25,
  PS_PAST_PARTICIPATION_FAIL = 26,
  PS_SUPPLIER_QUOTA_ALLOCATION_FULL = 27,
  PS_INVALID_SURVEY = 28,
  PS_LOI_THRESHOLD_FAILURE = 29,
  PS_GROUPING_TERMINATION = 32,
  PS_TEMP_EXCLUSION = 34,
  PS_NO_MATCHED_QUOTAS = 35,
  PS_MAX_IP_THROTTLING_TERMINATION = 36,
  PS_QUOTA_THROTTLING_TERMINATION = 37,
  PS_PSID_GEO_TERMINATION = 38,
  PS_TRUESAMPLE_FAIL = 39,
  PS_GEOIP_FAIL = 40,
  PS_BOT_FAIL = 41,
  PS_BLACKLIST_FAIL = 42,
  PS_ANONYMOUS_FAIL = 43,
  PS_TERMINATION_EXTENDED = 45,
  PS_TERMINATION_CUSTOM = 46,
  PS_QUOTAFULL_EXTENDED = 47,
  PS_QUOTAFULL_CUSTOM = 48,
  PS_INCLUDE_FAIL = 49,
  PS_EXCLUDE_FAIL = 50,
  PS_RVID_HASH_FAIL = 54,
  PS_UNIQUE_LINK_TERMINATION = 55,
  PS_UNAUTHORIZED_AUGMENT = 56,
  PS_SUPPLIER_QUOTAFULL = 57,
  PS_SUPPLIER_QUOTA_THROTTLING_TERMINATION = 58,
  PS_BUYER_CONFIG_ERROR = 59,
  PS_JS_FAIL = 60,
  PS_QBP_GROUPING_TERMINATION = 61,
  PS_NOPURESCORE = 62,
  PS_BLACKLIST_DATA_QUALITY = 63,
  PS_BLACKLIST_DATA_QUALITY_2 = 64,
  PS_RVID_IPSTATUS_FAIL = 65,
  PS_RVID_FRAUDRISK_FAIL = 66,
  PS_SC_FRAUDSTER_FAIL = 67,
  PS_SC_THREAT_FAIL = 68,
  PS_DF_DUPE = 70,
  PS_SC_SEC_HASH_FAIL = 71,
  PS_BLACKLIST_DATA_QUALITY_3 = 72,
  PS_TRANSACTION_FRAUD = 73,
  PS_RESPONDENT_REDIRECT_FAIL = 74,
  PS_BLACKLIST_DATA_QUALITY_4 = 75,
  PS_DQ_HONEYPOT_FAIL = 78,
  PS_PURETEXT_DEDUPE_FAIL = 79,
  PS_AI_TEXT_FAIL = 80,
  PS_PURETEXT_LANGUAGE_FAIL = 81,
  PS_SURVEY_SIGNATURE_FAIL = 82,
  PS_BROWSER_MANIPULATION_FAIL = 83,
  PS_RD_PREDUPE = 85,
  PS_RD_DEDUPE_GROUPING_TERMINATION = 86,
}

export const PS_STATUS_TO_BUYER_IGNORE = [
  PSStatusList.PS_QUOTAFULL_CORE,
  PSStatusList.PS_TERMINATION_CORE,
  PSStatusList.PS_QUALITY,
  PSStatusList.PS_SURVEY_CLOSED_TERMINATION,
  PSStatusList.PS_SURVEY_PAUSED_TERMINATION,
  PSStatusList.PS_UNOPENED_QUOTA_TERM,
  PSStatusList.PS_SUPPLIER_ALLOCATION_FULL,
  PSStatusList.PS_PAST_PARTICIPATION_FAIL,
  PSStatusList.PS_SUPPLIER_QUOTA_ALLOCATION_FULL,
  PSStatusList.PS_INVALID_SURVEY,
  PSStatusList.PS_TEMP_EXCLUSION,
  PSStatusList.PS_NO_MATCHED_QUOTAS,
  PSStatusList.PS_MAX_IP_THROTTLING_TERMINATION,
  PSStatusList.PS_QUOTA_THROTTLING_TERMINATION,
  PSStatusList.PS_PSID_GEO_TERMINATION,
  PSStatusList.PS_TRUESAMPLE_FAIL,
  PSStatusList.PS_GEOIP_FAIL,
  PSStatusList.PS_BOT_FAIL,
  PSStatusList.PS_ANONYMOUS_FAIL,
  PSStatusList.PS_QUOTAFULL_EXTENDED,
  PSStatusList.PS_QUOTAFULL_CUSTOM,
  PSStatusList.PS_INCLUDE_FAIL,
  PSStatusList.PS_EXCLUDE_FAIL,
  PSStatusList.PS_RVID_HASH_FAIL,
  PSStatusList.PS_UNAUTHORIZED_AUGMENT,
  PSStatusList.PS_SUPPLIER_QUOTAFULL,
  PSStatusList.PS_SUPPLIER_QUOTA_THROTTLING_TERMINATION,
  PSStatusList.PS_BUYER_CONFIG_ERROR,
  PSStatusList.PS_JS_FAIL,
  PSStatusList.PS_QBP_GROUPING_TERMINATION,
  PSStatusList.PS_NOPURESCORE,
  PSStatusList.PS_BLACKLIST_DATA_QUALITY,
  PSStatusList.PS_BLACKLIST_DATA_QUALITY_2,
  PSStatusList.PS_RVID_IPSTATUS_FAIL,
  PSStatusList.PS_RVID_FRAUDRISK_FAIL,
  PSStatusList.PS_SC_FRAUDSTER_FAIL,
  PSStatusList.PS_SC_THREAT_FAIL,
  PSStatusList.PS_DF_DUPE,
  PSStatusList.PS_SC_SEC_HASH_FAIL,
  PSStatusList.PS_BLACKLIST_DATA_QUALITY_3,
  PSStatusList.PS_TRANSACTION_FRAUD,
  PSStatusList.PS_RESPONDENT_REDIRECT_FAIL,
  PSStatusList.PS_BLACKLIST_DATA_QUALITY_4,
  PSStatusList.PS_DQ_HONEYPOT_FAIL,
  PSStatusList.PS_PURETEXT_DEDUPE_FAIL,
  PSStatusList.PS_AI_TEXT_FAIL,
  PSStatusList.PS_SURVEY_SIGNATURE_FAIL,
  PSStatusList.PS_BROWSER_MANIPULATION_FAIL,
  PSStatusList.PS_RD_PREDUPE,
  PSStatusList.PS_RD_DEDUPE_GROUPING_TERMINATION,
];
