import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { Subscription } from 'rxjs';
import { Constants as TransStatusConstants } from '../../constants/trans-status';
import { OperatorSettingsService } from '../operator-settings.service';
import { OperatorSettingsResponse } from '../operator-settings.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { notifyMessage } from '../../constants/notify-message';
import { Constants as OperatorSettingsConstants } from '../operator-settings-constants';

@Component({
  selector: 'ps-global-settings',
  templateUrl: './global-settings.component.html',
  styleUrls: ['./global-settings.component.css'],
})
export class GlobalSettingsComponent implements OnInit, OnDestroy {
  form!: FormGroup;
  private _subscriptions: Subscription = new Subscription();
  public psTransactionStatuses: PsTransactionStatuses[] =
    TransStatusConstants.TRANS_STATUS_LIST;
  public asrTransactionStatusesObj: PsTransactionStatuses[] = [];
  constructor(
    private _fb: FormBuilder,
    private _operatorSettingsService: OperatorSettingsService,
    private _toastr: ToasterService
  ) {}

  ngOnInit(): void {
    this._generateForm();
    this._getOperatorSettings();
  }

  private _generateForm() {
    this.form = this._fb.group({
      enablePSIDV3Plus: [false, [Validators.required]],
      dynamic_profiler: [false, [Validators.required]],
      enableCaptcha: [true, [Validators.required]],
      dp_max_question_count: [0, [Validators.required]],
      company: [null, [Validators.required]],
      validStatusForAsrRedirection: [[]],
      fma: this._fb.group({
        minimumEPC: [0],
        minimumCPI: [0],
        minimumPPM: [0],
        minimumQPG: [0],
        minimumOQP: [0],
        minimumBDP: [0],
      }),
      fmaDAL: this._fb.group({
        minimumEPC: [0],
        minimumCPI: [0],
        minimumPPM: [0],
        minimumOQP: [0],
        minimumBDP: [0],
      }),
      block_size_min: [null],
      block_size_max: [null],
      block_size_coefficient: [null],
      f2f: this._fb.group({
        f2fEnabled: [false, [Validators.required]],
        percentAllocation: [0],
        percentFieldTime: [0],
      }),
      enableRecaptchaV3: [false, [Validators.required]],
      reconPercentageLimit: [0, [Validators.required]],
      similarSurveySettings: this._fb.group({
        similarSurveySearchLimit: [
          '',
          [Validators.required, Validators.min(1)],
        ],
        completeRange: [0, [Validators.required]],
        enableCompleteRange: [true, [Validators.required]],
        loiRange: [0, [Validators.required]],
        enableLoiRange: [true, [Validators.required]],
        irRange: [0, [Validators.required]],
        enableIrRange: [true, [Validators.required]],
        fieldTimeRange: [0, [Validators.required]],
        enableFieldTimeRange: [true, [Validators.required]],
      }),
      difficultQuotaSettings: this._fb.group({
        minimumFeasibility: this._fb.group({
          enabled: [true, [Validators.required]],
          value: [0, [Validators.required]],
        }),
      }),
      activityLogSettings: this._fb.group({
        defaultExpirationTime: [
          OperatorSettingsConstants.DEFAULT_ACTIVITY_LOG_EXPIRATION_TIME,
          [Validators.required, Validators.min(1)],
        ],
      }),
    });
  }

  private _getOperatorSettings() {
    const getGlobalSetting = this._operatorSettingsService
      .getOperatorSettings()
      .subscribe(
        (resp: OperatorSettingsResponse) => {
          const operator = resp.operator[0];
          this.form.patchValue(operator);
          this._getPsTransactionStatusesName();
        },
        (error) => {
          this._toastr.error(error.error.msg);
        }
      );
    this._subscriptions.add(getGlobalSetting);
  }

  private _getPsTransactionStatusesName() {
    this.asrTransactionStatusesObj = this.psTransactionStatuses.filter(
      (status: PsTransactionStatuses) =>
        this.form.value.validStatusForAsrRedirection.indexOf(status.id) > -1
    );
  }

  selectAsrTransactionStatuses(asrTransactionStatuses: AsrTransactionStatuses) {
    this.form.value.validStatusForAsrRedirection = asrTransactionStatuses.map(
      (status: PsTransactionStatuses) => status.id
    );
  }

  updateGlobalSettings() {
    const updateObj = this.form.value;
    const isValid = this._validateGlobalSettings(updateObj);
    if (!isValid) {
      return;
    }
    this._operatorSettingsService
      .updateOperatorSettings(updateObj.company, updateObj)
      .subscribe(
        (res) => {
          this._toastr.success(
            notifyMessage.successMessage.OPERATOR_SETTINGS.GLOBAL_SETTINGS
              .SETTINGS_SAVED
          );
        },
        (error) => {
          this._toastr.error(
            error.error.msg || notifyMessage.errorMessage.SERVER_ERROR
          );
        }
      );
  }

  private _validateGlobalSettings(updateObj: Operator) {
    if (updateObj.dynamic_profiler && !updateObj.dp_max_question_count) {
      this._toastr.warning(
        notifyMessage.warningMessage.OPERATOR_SETTINGS.GLOBAL_SETTINGS
          .DYNAMIC_PROFILER_MAXIMUM_QUESTIONS
      );
      return false;
    }
    if (
      Number(
        this.form.controls.similarSurveySettings.value.similarSurveySearchLimit
      ) <= 0
    ) {
      return this._toastr.error(
        notifyMessage.errorMessage.OPERATOR_SETTINGS.GLOBAL_SETTINGS
          .INVALID_SIMILAR_SURVEY_SEARCH_LIMIT
      );
    }

    if (
      (updateObj.activityLogSettings as ActivityLogSettings)
        .defaultExpirationTime <= 0
    ) {
      return this._toastr.error(
        notifyMessage.errorMessage.OPERATOR_SETTINGS.GLOBAL_SETTINGS
          .INVALID_ACTIVITY_LOG_EXPIRATION_TIME
      );
    }

    return true;
  }

  public restrictPercentageOverflow() {
    if (Number(this.form.controls.f2f.value.percentAllocation) > 100) {
      this.form.controls.f2f.patchValue({
        percentAllocation: 100,
      });
    }
    if (Number(this.form.controls.f2f.value.percentFieldTime) > 100) {
      this.form.controls.f2f.patchValue({
        percentFieldTime: 100,
      });
    }
  }

  public onInputChange(field: string) {
    const control = this.form.get(`similarSurveySettings.${field}`);
    if (control) {
      const value = control.value;
      if (value === null || value === '') {
        control.patchValue(0);
      }
    }
  }

  public onMinimumFeasibilityInputChange() {
    const control = this.form
      .get(`difficultQuotaSettings`)
      ?.get(`minimumFeasibility`)
      ?.get('value');
    if (control) {
      const value = control?.value;
      if (value === null || value === '' || value < 0) {
        control.patchValue(0);
      }

      if (value > 100) {
        control.patchValue(100);
      }
    }
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}

interface Operator {
  [key: string]: unknown;
}

interface AsrTransactionStatuses {
  [x: string]: any;
}

interface PsTransactionStatuses {
  name: string;
  id: number;
}

interface ActivityLogSettings {
  defaultExpirationTime: number;
}
