import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportDownloadService {
  private readonly _baseUrl = environment.pureSpectrum.url;
  constructor(private _http: HttpClient) {}

  private _extractFilename(
    headers: HttpHeaders,
    defaultFilename: string
  ): string {
    const contentDisposition = headers.get('Content-Disposition');
    if (contentDisposition) {
      const match = contentDisposition.match(/filename="(.+?)"/);
      if (match) {
        return match[1]; // Extracted filename
      }
    }
    return defaultFilename;
  }

  downloadRewardCSV(companyId: number | null, month: string, year: number) {
    let apiUrl = `${this._baseUrl}/micro-rewards/v1/report?month=${month}&year=${year}`;
    if (companyId) {
      apiUrl += `&supId=${companyId}`;
    }
    this._http
      .get(apiUrl, { observe: 'response', responseType: 'blob' })
      .subscribe((response) => {
        const blob = response.body!;
        // Extract filename from Content-Disposition header
        const defaultFilename = `micro_reward_report_${month}_${year}.csv`;
        const filename = this._extractFilename(
          response.headers,
          defaultFilename
        );

        // Create a link and trigger download
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = filename;
        a.click();
        URL.revokeObjectURL(objectUrl);
      });
  }
}
