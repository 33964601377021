import { Component, OnDestroy, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../app/auth/auth.service';
import { ReportService } from './report-service/report.service';
import { DownLoginReportInterface } from '../download-reports/report-response.interface';
import { ReportDownloadService } from '../../shared/services/report-service/report.service';
import {
  Constants,
  PS_STATUS_TO_BUYER_IGNORE,
} from '../../constants/trans-status';
import {
  Constants as InvoiceConstants,
  MONTH_NAME_TO_NUMBER,
  MONTH_NUMBER_TO_NAME,
} from '../invoice-operation/invoice-operation.constants';

@Component({
  selector: 'ps-download-reports',
  templateUrl: './download-reports.component.html',
  styleUrls: ['./download-reports.component.css'],
})
export class DownloadReportsComponent implements OnInit, OnDestroy {
  private _subscription: Subscription = new Subscription();
  private _selectedCompanies = [];
  public isOperatorUser = this._auth.userType === 'operator' ? true : false;
  public isSupplierUser = this._auth.userType === 'supplier' ? true : false;
  public tabs: Tab[] = Constants.REPORTS_TAB;
  public activeTab: Tab = this.tabs[0];
  public selectMonth =
    MONTH_NUMBER_TO_NAME[
      this.convertNumberToDoubleDigit(new Date().getMonth() + 1)
    ];
  public months = InvoiceConstants.MONTHS_TO_DISPLAY;
  public selectYear = new Date().getFullYear();
  public years = InvoiceConstants.YEARS;
  public isSupplier: boolean = false;

  constructor(
    private _toastr: ToasterService,
    private _auth: AuthService,
    private _reportService: ReportService,
    public datepipe: DatePipe,
    private _reportDownloadService: ReportDownloadService
  ) {}
  strReportsArr = [];

  public daterange: any = {
    start: { _d: Date.now() },
    end: { _d: Date.now() },
  };
  public options: any = {
    locale: { format: 'YYYY-MM-DD' },
    alwaysShowCalendars: false,
  };

  public transactionStatus = Constants.TRANS_STATUS_LIST;

  ngOnInit(): void {
    if (
      this._auth.user!.buyerAcssLvls !== 'none' &&
      !this._auth.buyerConfig!.enableClassicSTR
    ) {
      this.transactionStatus = this.transactionStatus.filter(
        (status) => !PS_STATUS_TO_BUYER_IGNORE.includes(status.id)
      );
    }
    this.getStrReports(this._auth.user!.id);
    if (this._auth.user!.supplierAcssLvls !== 'none') {
      this.isSupplier = true;
    }
  }

  convertNumberToDoubleDigit(numberToConvert: number) {
    let number = `${numberToConvert}`;
    if (number.length == 1) {
      return `0${numberToConvert}`;
    }
    return number;
  }

  public selectedDate(value: any) {
    this.daterange.start = value.start;
    this.daterange.end = value.end;
  }

  checkActiveTab(activeIndex: any) {
    this.activeTab = this.tabs[activeIndex];
  }

  getStrReports(userId: number) {
    const strReports = this._reportService.getPsReports(userId).subscribe(
      (res: any) => {
        this.strReportsArr = res.result;
      },
      (err: { error: { msg: any } }) => {
        const errorMessage = err.error.msg;
        this._toastr.error(errorMessage);
      }
    );
    this._subscription.add(strReports);
  }
  saveReportData() {
    const payload = {
      currentTimestamp: Date.now(),
      endDt: this.datepipe.transform(this.daterange.end._d, 'yyyy-MM-dd'),
      stDt: this.datepipe.transform(this.daterange.start._d, 'yyyy-MM-dd'),
      st: this._selectedCompanies,
      u: this._auth.user!.id,
      c: this._auth.user!.cmp,
    };
    this._reportService.saveReportData(payload).subscribe(
      (res) => {
        this._toastr.success(res.msg);
        this.getStrReports(this._auth.user!.id);
      },
      (err: { error: { msg: any } }) => {
        const errorMessage = err.error.msg;
        this._toastr.error(errorMessage);
      }
    );
  }
  selectedCmp(companies: any) {
    this._selectedCompanies = companies;
  }
  downloadLoginReport() {
    const downloadLoginReport = this._reportService
      .downloadLoginReport()
      .subscribe(
        (res: DownLoginReportInterface) => {
          window.location.assign(
            this._reportService.downloadFile(res.fileName, this._auth.token)
          );
        },
        (err: { error: { msg: string } }) => {
          const errorMessage = err.error.msg;
          this._toastr.error(errorMessage);
        }
      );
    this._subscription.add(downloadLoginReport);
  }

  /**
   * Show date range is required message if date range is not selected by the user
   */
  handleDateRangeChange() {
    // TODO
  }
  getSupplierReport(type: string) {
    const year = this.selectYear;
    const month = MONTH_NAME_TO_NUMBER[this.selectMonth];
    if (type === 'reward') {
      const companyId = null; // null because for supplier logged in users we dont send company id in request params
      this._reportDownloadService.downloadRewardCSV(companyId, month, year);
      return;
    } else {
      const companyId = this._auth.user!.cmp;
      window.location.assign(
        this._reportService.downloadStatementReport(
          companyId,
          month,
          year,
          type
        )
      );
    }
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
interface Tab {
  title: string;
  active: boolean;
  uniqueId: string;
}
