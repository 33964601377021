import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { Subscription } from 'rxjs';

import { AuthService } from '../../auth/auth.service';
import {
  SupplierSettingsService,
  SupplierSettings,
} from './supplier-settings-service/supplier-settings.service';
import { Constants, messages } from './supplier-settings-constant';
import { User } from '../../shared/types';
import { notifyMessage } from '../../constants/notify-message';

@Component({
  selector: 'ps-supplier-settings',
  templateUrl: './supplier-settings.component.html',
  styleUrls: ['./supplier-settings.component.css'],
})
export class SupplierSettingsComponent implements OnInit, OnDestroy {
  form!: FormGroup;
  userDetails!: User;
  supplierSetting!: SupplierSettings;

  private _subscription: Subscription = new Subscription();
  constructor(
    private _fb: FormBuilder,
    private _auth: AuthService,
    private _toastr: ToasterService,
    private _manageSupplierSettingsService: SupplierSettingsService
  ) {}

  ngOnInit(): void {
    this.generateForm();
    this.userDetails = this._auth.user!;
    this.getManageSupplierSetting();
  }
  public tabs: Tab[] = Constants.MANAGE_SUPPLIER_TABS;
  public activeTab: Tab = this.tabs[0];

  generateForm() {
    this.form = this._fb.group({
      cmp: [null, [Validators.required]],
      dynamicProfiler: [false, [Validators.required]],
      isAsrRedirectionEnable: [false, [Validators.required]],
      isDataQualityQuestionsActive: [false, [Validators.required]],
      isExpose: [false, [Validators.required]],
      isHashing: [false, [Validators.required]],
      isHashingInbound: [false, [Validators.required]],
      isNotify: [false, [Validators.required]],
      isPrivacyPolicyActive: [false, [Validators.required]],
      isRecontact: [false, [Validators.required]],
      isModularSurvey: [false, [Validators.required]],
      max_loi: [null],
      min_incidence: [null],
      private_key: [''],
      supp_url_completes: [''],
      supp_url_overquota: [''],
      supp_url_security_terms: [''],
      supp_url_terms: [''],
      supplier_type: [''],
      iss2sCallback: [false],
      isCompleteToNonCompleteURL: [''],
      isNonCompleteToCompleteURL: [''],
      s2sCompleteCallbackURL: [''],
      s2sMicroRewardCallbackURL: [''],
      microSurveySetting: this._fb.group({
        active: [false],
        showConsent: [true],
        currencyName: [''],
        conversionValue: [0],
        rewardAmount: [0],
      }),
      variable_mapping: this._fb.group({
        ps_supplier_sid: this._fb.group({
          incoming: [
            '',
            [Validators.pattern('^[a-zA-Z0-9_]+$'), Validators.maxLength(36)],
          ],
          outgoing: [
            '',
            [Validators.pattern('^[a-zA-Z0-9_]+$'), Validators.maxLength(36)],
          ],
        }),
        ps_supplier_respondent_id: this._fb.group({
          incoming: [
            '',
            [Validators.pattern('^[a-zA-Z0-9_]+$'), Validators.maxLength(36)],
          ],
          outgoing: [
            '',
            [Validators.pattern('^[a-zA-Z0-9_]+$'), Validators.maxLength(36)],
          ],
        }),
        ps_custom_svar1: this._fb.group({
          incoming: [
            '',
            [Validators.pattern('^[a-zA-Z0-9_]+$'), Validators.maxLength(36)],
          ],
          outgoing: [
            '',
            [Validators.pattern('^[a-zA-Z0-9_]+$'), Validators.maxLength(36)],
          ],
        }),
        ps_custom_svar2: this._fb.group({
          incoming: [
            '',
            [Validators.pattern('^[a-zA-Z0-9_]+$'), Validators.maxLength(36)],
          ],
          outgoing: [
            '',
            [Validators.pattern('^[a-zA-Z0-9_]+$'), Validators.maxLength(36)],
          ],
        }),
        ps_term_rsn: this._fb.group({
          outgoing: [
            '',
            [Validators.pattern('^[a-zA-Z0-9_]+$'), Validators.maxLength(36)],
          ],
        }),
        ps_psid: [
          '',
          [Validators.pattern('^[a-zA-Z0-9_]+$'), Validators.maxLength(36)],
        ],
        ps_rstatus: this._fb.group({
          incoming: [
            '',
            [Validators.pattern('^[a-zA-Z0-9_]+$'), Validators.maxLength(36)],
          ],
          outgoing: [
            '',
            [Validators.pattern('^[a-zA-Z0-9_]+$'), Validators.maxLength(36)],
          ],
        }),
        ps_hash: this._fb.group({
          incoming: [
            'ps_s_hash',
            [Validators.pattern('^[a-zA-Z0-9_]+$'), Validators.maxLength(36)],
          ],
          outgoing: [
            'ps_s_hash',
            [Validators.pattern('^[a-zA-Z0-9_]+$'), Validators.maxLength(36)],
          ],
        }),
        ps_oqid: this._fb.group({
          outgoing: [
            '',
            [Validators.pattern('^[a-zA-Z0-9_]+$'), Validators.maxLength(36)],
          ],
        }),
        ps_cpi: this._fb.group({
          outgoing: [
            '',
            [Validators.pattern('^[a-zA-Z0-9_]+$'), Validators.maxLength(36)],
          ],
        }),
      }),
    });
  }

  checkActiveTab(activeIndex: any) {
    this.activeTab = this.tabs[activeIndex];
  }

  getManageSupplierSetting() {
    const supplierSetting = this._manageSupplierSettingsService
      .getManageSupplierSettings(this.userDetails.cmp)
      .subscribe(
        (supplier) => {
          this.form.patchValue(supplier);
          this.supplierSetting = supplier;

          console.log(supplier);
          console.log('--------');
          console.log(this.supplierSetting);

          // Remove the MICRO SURVEYS tab if showMicrosurveySettings is false
          if (
            !this.supplierSetting.microSurveySetting?.showMicrosurveySettings
          ) {
            this.tabs = this.tabs.filter(
              (tab) => tab.title !== 'MICRO SURVEYS'
            );

            if (this.activeTab.title === 'MICRO SURVEYS') {
              this.activeTab = this.tabs[0];
            }
          }
        },
        (err) => {
          const errorMessage = err.error.msg;
          this._toastr.error(errorMessage);
        }
      );
    this._subscription.add(supplierSetting);
  }

  saveSettingData() {
    const updateObj = { ...this.form.value };

    // Reattach the field from supplierSetting if it exists so we don't lose it when saving
    if (!updateObj.microSurveySetting) {
      updateObj.microSurveySetting = {};
    }
    updateObj.microSurveySetting.showMicrosurveySettings =
      this.supplierSetting?.microSurveySetting?.showMicrosurveySettings;

    if (updateObj.variable_mapping.ps_rstatus) {
      updateObj.variable_mapping.ps_rstatus.incoming =
        this.supplierSetting?.variable_mapping?.ps_rstatus?.incoming;
    }
    if (
      (updateObj.isHashing || updateObj.isHashingInbound) &&
      !updateObj.private_key
    ) {
      this._toastr.error(messages.ERROR_MESSAGE.EMPTY_HASH_KEY);
    } else {
      if (updateObj.max_loi === null) {
        delete updateObj.max_loi; // with null values API schema validation throws error
      }
      if (updateObj.min_incidence === null) {
        delete updateObj.min_incidence; // with null values API schema validation throws error
      }

      const updateSupplierSetting = this._manageSupplierSettingsService
        .updateManageSupplierSettings(updateObj, this.userDetails.cmp)
        .subscribe({
          complete: () => {
            this._toastr.success(
              notifyMessage.successMessage.SUPPLIER_INFO_UPDATED
            );
          },
          error: (err) => {
            const errorMessage = err.error.ps_api_response_message;
            this._toastr.error(errorMessage);
          },
        });
      this._subscription.add(updateSupplierSetting);
    }
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}

interface Tab {
  title: string;
  active: boolean;
}
